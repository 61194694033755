<template>
  <v-flex> </v-flex>
</template>

<script>
export default {
  name: "CartReturn",
};
</script>

<style scoped></style>
