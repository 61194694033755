<template>
  <v-layout row wrap class="catalog-items-panel pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15">
    <v-flex xs12>
      <v-card class="mx-auto checkout-customer-panel">
        <v-card-title>{{ $vuetify.lang.t("$vuetify.payment") }}</v-card-title>

        <v-card-text>
          <div class="text-center">
            <v-progress-circular :size="70" :width="7" indeterminate color="amber"></v-progress-circular>

            <div>{{ $vuetify.lang.t("$vuetify.msgCheckInstructions") }}</div>
          </div>
        </v-card-text>
        <br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br />
      </v-card>
      <v-row class="ma-2" x-large justify="center" align="center"></v-row>
      <v-spacer />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "CheckoutClient",
  data: () => ({
    taxNumber: undefined,
    customerName: null,
  }),
  methods: {
    nextCatalog() {
      this.$store.dispatch("cart/setCartEmpty");
      this.$router.push({ name: "selfcheckout" }).catch(() => {
        /* NavigationDuplicated if the "Pay" button is clicked again */
      });
    },
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.nextCatalog();
    }, 5000);
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
