<template>
  <v-layout row wrap class="catalog-items-panel pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15">
    <v-flex xs12>
      <!--      @deprecated since the CashDrawers are chosen previously-->
      <!--      <v-card v-if="cashDrawers.length > 1" class="mx-auto">-->
      <!--        <v-card-text>-->
      <!--          <v-select-->
      <!--            label="Caixa"-->
      <!--            v-model="selectedCashDrawer"-->
      <!--            :items="cashDrawers"-->
      <!--            :item-text="(obj) => obj['description']"-->
      <!--            :item-value="(obj) => obj['tillId']"-->
      <!--            return-object-->
      <!--          ></v-select>-->
      <!--        </v-card-text>-->
      <!--      </v-card>-->

      <!--      <v-spacer class="my-5"></v-spacer>-->

      <v-card class="mx-auto checkout-customer-panel">
        <!-- @modified ana.castro 2021.09.15 SABO-2148 -->
        <!-- Removing the Title due to the lack of space on the POS screen -->
        <!--        <v-card-title class="text-h6"-->
        <!--          >Cliente-->
        <!--          <v-chip v-if="customer.TotalPoints" label :ripple="false"-->
        <!--            >{{ customer.TotalPoints }} Pontos-->
        <!--          </v-chip>-->
        <!--        </v-card-title>-->

        <v-card-text>
          <v-spacer class="my-3"></v-spacer>
          <v-row v-if="cartType !== 'return' && cartType !== 'returnTotal'">
            <v-col cols="12" md="8">
              <v-text-field
                v-on:keyup="submitCustomerSearch"
                v-model="searchText"
                ref="searchCustomerInput"
                v-click-outside="clearCustomerSearch"
                solo
                clearable
                :placeholder="$vuetify.lang.t('$vuetify.customerSearchMessage')"
                prepend-inner-icon="fas fa-user"
                :append-icon="'fal fa-search'"
                @click:append-outer="applyCustomerSearch"
                @click:append="applyCustomerSearch"
                @click:clear="clearCustomerSearch"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-actions>
                <v-btn v-if="displayButtonAddCustomer" text @click="addCustomer" color="primary">
                  {{ $vuetify.lang.t("$vuetify.newClient") }}
                </v-btn>
                <!-- Button to display a Dialog which contains the Loyalty Campaigns available for the Customer -->
                <v-btn
                  v-if="customer && customer.Id && customerLoyaltyCampaignsValue.length > 0"
                  text
                  @click="displayDialogLoyaltyCampaigns = true"
                  color="primary"
                >
                  {{ $vuetify.lang.t("$vuetify.campaigns") }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="taxNumber"
                ref="taxNumberInput"
                :label="$vuetify.lang.t('$vuetify.taxNumber')"
                :rules="taxNumberRules"
                :disabled="!isCustomerInfoEnabled()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="loyaltyCardId"
                v-on:keyup="submitVoucherSearch"
                :label="$vuetify.lang.t('$vuetify.loyaltyCardVoucherNumberMessage')"
                :append-icon="'fal fa-search'"
                @click:append-outer="applyVoucherSearch"
                @click:append="applyVoucherSearch"
                :disabled="!isCustomerInfoEnabled()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="customerName"
                :label="$vuetify.lang.t('$vuetify.name')"
                :disabled="!isCustomerInfoEnabled()"
              ></v-text-field>
            </v-col>
            <v-col v-if="cartType !== 'return' && cartType !== 'returnTotal'" cols="12" md="4">
              <v-chip v-if="customer.TotalPoints" label :ripple="false">
                <v-avatar>
                  <v-icon>fa-asterisk</v-icon>
                </v-avatar>
                &nbsp;{{ customer.TotalPoints }} {{ $vuetify.lang.t("$vuetify.points") }}
              </v-chip>
            </v-col>
            <v-col v-if="cartType !== 'return' && cartType !== 'returnTotal'" cols="12" md="2">
              <v-btn text @click="displaySaleInvoiceDataDialog = true" color="primary">
                {{ $vuetify.lang.t("$vuetify.more") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-spacer
        class="my-3"
        v-if="modelExtensionFieldsForSales.length > 0 && cartType !== 'return' && cartType !== 'returnTotal'"
      ></v-spacer>

      <!-- The Sale's Checkout may have extra fields which have to be dynamically added to the interface. These fields
       are based on the Extension fields defined on the IndexedDB extensionFields table.
           At the moment, only Select fields are being supported. But in the future the field's type might be dynamically -->
      <v-card
        class="mx-auto checkout-sale-attributes-panel"
        v-if="modelExtensionFieldsForSales.length > 0 && cartType !== 'return' && cartType !== 'returnTotal'"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-for="(extensionField, i) in modelExtensionFieldsForSalesSelect"
                :key="i"
                v-model="modelExtensionFieldsForSalesValues[extensionField.data['Column']]"
                :items="countryOptions()"
                :label="extensionField.name"
                :required="extensionField.required"
                item-value="value"
                item-text="text"
                return-object
                v-on:change="isSubmitEnabled"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog v-model="displayCustomerDataDialog" persistent max-width="600px">
        <Customer @close-dialog="closeCustomerDialog" @create-customer="createCustomer"></Customer>
      </v-dialog>

      <!-- Sale Invoice extra data. For example: address-->
      <v-dialog v-model="displaySaleInvoiceDataDialog" persistent max-width="600px">
        <SaleInvoiceData
          :saleInvoiceData="this.saleInvoiceData"
          @close-dialog="closeSaleInvoiceDataDialog"
          @sale-invoice-data="setSaleInvoiceData"
        ></SaleInvoiceData>
      </v-dialog>

      <v-spacer class="my-5"></v-spacer>
      <v-card class="mx-auto checkout-payment-panel">
        <v-card-text>
          <PaymentMethods
            v-for="(paymentMethodValue, i) in paymentMethodsValues"
            :key="i"
            :paymentMethodValue="paymentMethodValue"
            :paymentMethods="paymentMethods"
            :index="i"
            :drawMinus="paymentMethodsValues.length > 1"
            :drawPlus="i === paymentMethodsValues.length - 1"
            :labelPaymentMethod="labelPaymentMethod"
            :cartType="cartType"
            @modifyPaymentMethodValue="modifyPaymentMethodValue"
            @addPaymentMethod="addPaymentMethod"
            @removePaymentMethod="removePaymentMethod"
            @submitSale="submit"
          ></PaymentMethods>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="comments"
                :label="$vuetify.lang.t('$vuetify.obs')"
                regular
                counter
                :maxlength="commentsMaxLength"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="ma-2" x-large justify="center" align="center"></v-row>
      <v-spacer />

      <div class="text-center">
        <v-row justify="center" align="center">
          <!-- Secção em que há Troco a dar,
																					mas apenas há um Método de Pagamento em que é possível dar o Troco,
																					portanto apenas é apresentado o Chip com o Valor do Troco -->
          <v-col
            v-if="
              cartTotalAmount - totalPaymentMethods < 0 &&
              changePaymentMethodsValues.length === 1 &&
              changePaymentMethods.length === 1
            "
            cols="12"
            md="4"
          >
            <v-chip label :ripple="false" color="green" text-color="white" class="font-weight-bold">
              <v-icon left>fa-coins</v-icon>
              {{ $vuetify.lang.t("$vuetify.change") }} {{ (totalPaymentMethods - cartTotalAmount).toFixed(2) }}
              {{ currencySymbol }}
            </v-chip>
          </v-col>

          <!-- Secção em que há Troco a dar,
																						mas o Método de Pagamento utilizado para Pagar a Compra, não permite dar Troco -->
          <v-col
            v-else-if="totalPaymentMethods - cartTotalAmount > 0 && changePaymentMethodsValues.length === 0"
            cols="12"
            md="10"
          >
            <v-chip label :ripple="false" color="red" text-color="white">
              <v-icon left>fa-exclamation-triangle</v-icon>
              {{ $vuetify.lang.t("$vuetify.paymentMethodDoesNotAllowChange") }}
            </v-chip>
          </v-col>

          <!-- Secção em que há Troco a dar,
																						e há vários Métodos de Pagamento em que é possível dar o Troco -->
          <v-col
            v-else-if="
              totalPaymentMethods - cartTotalAmount > 0 &&
              changePaymentMethodsValues.length === 1 &&
              changePaymentMethods.length > 1
            "
            cols="12"
            md="8"
          >
            <v-card class="mx-auto">
              <v-card-text>
                <PaymentMethods
                  v-for="(changePaymentMethodValue, i) in changePaymentMethodsValues"
                  :key="i"
                  :paymentMethodValue="changePaymentMethodValue"
                  :paymentMethods="changePaymentMethods"
                  :index="i"
                  :drawMinus="false"
                  :drawPlus="false"
                  :disableValueInput="true"
                  :labelPaymentMethod="labelChangePaymentMethod"
                  @modifyChangePaymentMethodValue="modifyChangePaymentMethodValue"
                ></PaymentMethods>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-else-if="cartTotalAmount - totalPaymentMethods > 0" cols="12" md="8">
            <v-chip label :ripple="false" color="blue" text-color="white" class="font-weight-bold">
              <v-icon left>fa-coins</v-icon>
              {{ $vuetify.lang.t("$vuetify.moneyRemaining") }} {{ (cartTotalAmount - totalPaymentMethods).toFixed(2) }}
              {{ currencySymbol }}
            </v-chip>
          </v-col>
        </v-row>
      </div>

      <!-- TODO: posicionar corretamente
												TODO: deve ser múltiplo para permitir utilizar mais do que 1 vale na mesma compra (estilo Métodos de Pagamento);
												a questão de permitir remover Vales previamente selecionados, pensei em fazer como nos Métodos de Pagamento;
												mas depois pensei melhor e descomplicando, podemos aplicar a mesma regra de Campanhas de Fidelização: o operador
												tem de sair da área de finalização da Venda para fazer "reset"-->
      <!--Comentado Temporariamente -->
      <!--      <div class="text-center">
													<v-row class="ma-2" x-large justify="center" align="center">
														<v-text-field
															v-on:keyup="submitVoucherSearch"
															v-model="searchVoucherText"
															solo
															clearable
															placeholder="Código Vale"
															prepend-inner-icon="fas fa-ticket-alt"
															:append-icon="'fal fa-search'"
															@click:append-outer="applyVoucherSearch"
															@click:append="applyVoucherSearch"
														></v-text-field>
													</v-row>
													<v-spacer />
												</div>-->

      <v-dialog v-model="displayDocument" persistent max-width="500px">
        <DisplayPDF
          :source="saleDocumentSource"
          :sourceExtra="saleDocumentSourceAppend"
          :sourceExtraButtonName="$vuetify.lang.t('$vuetify.offerDocument')"
          :sourceExtraCopies="true"
          @close-pdf="closeDocument"
        ></DisplayPDF>

        <!-- <v-card>
																						<v-card-text style="height: 600px; overflow: auto">
																								<pdf ref="myPdfComponent" :src="saleDocumentSource"></pdf>
																						</v-card-text>
																								<v-card-actions>
																								<v-btn class="primary" large @click="$refs.myPdfComponent.print()">
																										<v-icon right small>fas fa-print</v-icon>
																										Imprimir
																								</v-btn>
																								<v-btn large text @click="closeDocument"> Fechar </v-btn>

																						</v-card-actions>
																		</v-card>-->
      </v-dialog>

      <!-- This Dialog lists the Loyalty Campaigns available for the Customer;
												the Loyalty Campaigns are divided in three types:
													1 - Vouchers with Value to discount;
													2 - Vouchers with a Percentage to discount
													3 - Campaigns.-->
      <v-dialog v-model="displayDialogLoyaltyCampaigns" persistent max-width="600px">
        <LoyaltyCampaigns
          :loyaltyCampaigns="customerLoyaltyCampaigns"
          :loyaltyCampaignsVouchersValue="customerLoyaltyCampaignsValue"
          :loyaltyCampaignsVouchersPercent="customerLoyaltyCampaignsPercent"
          @close-dialog="closeDialogCampaigns"
          @add-campaign="addCampaign"
        ></LoyaltyCampaigns>
      </v-dialog>

      <v-dialog v-model="displayPaymentMethodAutoDialog" content-class="custom-dialog" persistent max-width="300px">
        <v-card class="mx-auto">
          <v-card-title>
            <span>{{ displayPaymentMethodAutoDialogTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="paymentMethodAutoValue"
              :label="$vuetify.lang.t('$vuetify.identifier')"
              type="number"
              required
              ref="paymentMethodAutoInput"
              autofocus
            ></v-text-field>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closePaymentMethodAutoDialog" large> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
              <v-btn class="primary" large @click="validatePaymentMethodAuto()">
                {{ $vuetify.lang.t("$vuetify.validate") }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import {
  displayWithCents,
  getApiBaseUrl,
  getErrorMessage,
  isValidSaleDocType,
  round2Decimals,
  validateTaxNumberPT
} from "@/_helpers";
import { customersServices, paymentTypesService, salesServices } from "@/services";
import Customer from "@/components/Customer.vue";
import PaymentMethods from "@/components/PaymentMethods.vue";
import DisplayPDF from "@/components/DisplayPDF.vue";
import LoyaltyCampaigns from "@/components/LoyaltyCampaigns.vue";
import SaleInvoiceData from "@/components/SaleInvoiceData.vue";

export default {
  name: "CheckoutCart",
  components: { Customer, PaymentMethods, DisplayPDF, LoyaltyCampaigns, SaleInvoiceData },
  data: () => ({
    displayButtonAddCustomer: true,
    displayCustomerInfo: false,
    displayCustomerDataDialog: false,
    displayDialogLoyaltyCampaigns: false,
    customerLoyaltyCampaigns: [],
    customerLoyaltyCampaignsValue: [],
    customerLoyaltyCampaignsPercent: [],
    customerSelectedLoyaltyCampaigns: [],
    displaySaleDocument: false,
    displayBudgetDocument: false,
    searchText: "",
    paymentMethods: [],
    paymentMethodsValues: [],
    changePaymentMethods: [],
    changePaymentMethodsValues: [],
    customer: [],
    taxNumber: undefined,
    loyaltyCardId: null,
    customerName: null,
    comments: null,
    commentsMaxLength: 200,
    saleDocumentSource: null,
    saleDocumentSourceAppend: null,
    documentType: "FR",
    documentIdentifier: null,
    selectedCashDrawer: null,
    cashDrawers: [],
    labelPaymentMethod: null,
    labelChangePaymentMethod: null,
    //It might be necessary to add extra information to the Sale Document: address, for example.
    // Therefore, a dialog is displayed with the extra fields to fill in.
    displaySaleInvoiceDataDialog: false,
    saleInvoiceData: {},
    //The Sale's Checkout may have extra fields which have to be dynamically added to the interface.
    // These fields are based on the Extension fields defined on the IndexedDB extensionFields table.
    //A variable to store the Extension Fields related to Sales.
    modelExtensionFieldsForSales: [],
    //A variable to store the Extension Fields related to Sales and which type is a TABLE,
    // which means that the interface element must be a SELECT
    modelExtensionFieldsForSalesSelect: [],
    //A variable to store the chosen values for each Extension Field
    modelExtensionFieldsForSalesValues: {},
    countries: [],
    //@modified ana.castro 2023.07.03 SAFO-72
    //Variables related with Payment Methods which require giving extra information
    paymentMethodAuto: undefined,
    paymentMethodAutoType: undefined,
    paymentMethodAutoValue: undefined,
    displayPaymentMethodAutoDialog: false,
    displayPaymentMethodAutoDialogTitle: undefined,
  }),
  /**
   * Before accessing the Final Step of the Sale, it is necessary to verify if there is any open Cash Drawer.
   * If there's not, then the User is redirected to the interface where Cash Drawers are opened
   *
   * @modified ana.castro 2021-08-12 2128
   * */
  async beforeCreate() {
    let actionType = "CheckoutCart - beforeCreate";
    let actionValue = "Start";
    let actionData = {};
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    let isAnyCashDrawerOpen = 0;
    let isAnyCashDrawerDefault = 0;

    actionValue = "isAnyCashDrawerOpen - Start";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    isAnyCashDrawerOpen = await this.$store.dispatch("cashDrawers/isAnyCashDrawerOpen");

    actionValue = "isAnyCashDrawerOpen - End";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    if (!isAnyCashDrawerOpen) {
      actionValue = "Cashdrawer Not Open - Redirecting";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      await this.$router.push({ name: "cash-drawers-management-redirect" });
    } else {
      actionValue = "Cashdrawer Default?";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      isAnyCashDrawerDefault = await this.$store.dispatch("cashDrawers/getDefaultCashDrawers");
      if (isAnyCashDrawerDefault.length === 0) {
        actionValue = "Cashdrawer No Default - Redirecting";
        this.$store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
        });
        await this.$router.push({ name: "cash-drawers-management-redirect" });
      } else {
        actionValue = "Cashdrawer Default Found";
        this.$store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
        });
      }
    }
    actionValue = "End";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });
  },

  methods: {
    defineCustomer() {
      this.customer = [{ OrganizationName: this.$vuetify.lang.t("$vuetify.finalClient") }];
    },
    /**
     * This function looks up for a Customer which the tax number, telephone or loyalty card
     * match with the "searchText".
     * If a Customer is found and based on the Items on the Cart, the Loyalty Campaigns available
     * for the Customer are identified.
     **/
    applyCustomerSearch: function () {
      this.$store.dispatch("loader/setLoading", true);
      this.customerLoyaltyCampaigns = [];
      this.customerLoyaltyCampaignsValue = [];
      this.customerLoyaltyCampaignsPercent = [];
      this.customerSelectedLoyaltyCampaigns = [];

      //Everytime the Customer's search is used, the Campaigns which might be applied to the Cart are removed:
      //1 - the Campaigns stored on the Cart store -> appliedLoyaltyCampaigns
      //2 -the discounts applied to the Cart's Items -> discount_loyalty
      this.$store.dispatch("cart/removeCartDiscountLoyalty");
      if (this.searchText) {
        //Searching for a Customer which the tax number, telephone or loyalty card match with the "searchText
        customersServices.getByCardTaxNumberMobilePhone(this.searchText).then(
          (result) => {
            if (result.count === 0) {
              this.displayCustomerDataDialog = true;
            } else {
              this.displayButtonAddCustomer = false;
              this.displayCustomerInfo = true;
              this.customer = result.results[0];
              this.taxNumber =
                this.customer["FederalTaxId"] && this.customer["FederalTaxId"].toUpperCase().startsWith("PT")
                  ? this.customer["FederalTaxId"].toUpperCase().replace("PT", "")
                  : this.customer["FederalTaxId"];
              this.loyaltyCardId = this.customer["CardId"];
              this.customerName = this.customer["OrganizationName"];

              this.saleInvoiceData = {
                shipToAddressDetail: this.customer["AddressLine1"],
                shipToAddressPostalCode: this.customer["PostalCode"],
                shipToAddressCity: this.customer["PostalCodeName"],
                shipToAddressCountry: {
                  text: this.customer["CountryName"],
                  value: this.customer["ISOCountryId"],
                },
              };

              //Searching for the Loyalty Campaigns available for the Customer
              let obj = {
                items: this.cartItems,
                store: this.$route.params.store,
                taxNumber:
                  this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
                    ? this.taxNumber.toUpperCase().replace("PT", "")
                    : this.taxNumber,
                loyaltyCardId: this.loyaltyCardId,
                customerMobileTelephone: this.customer["Id"],
              };

              if (this.cartType !== "return" && this.cartType !== "returnTotal") {
                customersServices.getCustomerAvailableCampaigns(obj).then(
                  (result) => {
                    //Dividing the identified Loyalty Campaigns in three types:
                    // 1 - Vouchers with Value to discount;
                    // 2 - Vouchers with a Percentage to discount
                    // 3 - Campaigns
                    if (result.items.length > 0) {
                      for (var i = 0; i < result.items.length; i++) {
                        if (result.items[i].code && result.items[i].value) {
                          this.customerLoyaltyCampaignsValue.push(result.items[i]);
                        } else if (result.items[i].code && result.items[i].percent) {
                          this.customerLoyaltyCampaignsPercent.push(result.items[i]);
                        } else {
                          this.customerLoyaltyCampaigns.push(result.items[i]);
                        }
                      }

                      this.displayDialogLoyaltyCampaigns = true;
                    }
                  },
                  (error) => {
                    this.$store.dispatch(
                      "alert/error",
                      this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                        " " +
                        this.$vuetify.lang.t("$vuetify.errorIdAvailableCampaigns")
                    );
                    getErrorMessage(error, "Get Customer Available Campaigns", this);
                  }
                );
              }
            }
            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            getErrorMessage(error, "Getting the Customer Detail", this);
          }
        );
      } else {
        this.$store.dispatch(
          "alert/error",
          this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
            " " +
            this.$vuetify.lang.t("$vuetify.errorIdAvailableVouchers")
        );
        this.$store.dispatch("loader/setLoading", false);
      }
    },
    clearCustomerSearch() {
      this.$nextTick(() => (this.searchCustomerInputVisible = false));
    },
    submitCustomerSearch: function (e) {
      if (e.keyCode === 13) {
        this.applyCustomerSearch();
      }
    },
    submitVoucherSearch: function (e) {
      if (e.keyCode === 13) {
        this.applyVoucherSearch();
      }
    },
    applyVoucherSearch() {
      this.$store.dispatch("loader/setLoading", true);
      this.customerLoyaltyCampaigns = [];
      this.customerLoyaltyCampaignsValue = [];
      this.customerLoyaltyCampaignsPercent = [];
      this.customerSelectedLoyaltyCampaigns = [];
      if (this.loyaltyCardId) {
        let obj = {
          items: this.cartItems,
          store: this.$route.params.store,
          loyaltyCardId: this.loyaltyCardId,
        };

        customersServices.getCustomerAvailableCampaigns(obj).then(
          (result) => {
            //Dividing the identified Loyalty Campaigns in three types:
            // 1 - Vouchers with Value to discount;
            // 2 - Vouchers with a Percentage to discount
            // 3 - Campaigns
            if (result.items.length > 0) {
              for (var i = 0; i < result.items.length; i++) {
                if (result.items[i].code && result.items[i].value) {
                  this.customerLoyaltyCampaignsValue.push(result.items[i]);
                } else if (result.items[i].code && result.items[i].percent) {
                  this.customerLoyaltyCampaignsPercent.push(result.items[i]);
                } else {
                  this.customerLoyaltyCampaigns.push(result.items[i]);
                }
              }

              this.displayDialogLoyaltyCampaigns = true;
            } else {
              this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorIdAvailableVouchers"));
            }
            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            this.$store.dispatch(
              "alert/error",
              this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                " " +
                this.$vuetify.lang.t("$vuetify.errorIdAvailableVouchers")
            );
            getErrorMessage(error, "Get Voucher", this);
          }
        );
      } else {
        this.$store.dispatch(
          "alert/error",
          this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
            " " +
            this.$vuetify.lang.t("$vuetify.errorSearchValueNotIndicated")
        );
        this.$store.dispatch("loader/setLoading", false);
      }
    },
    addCustomer() {
      this.displayCustomerDataDialog = true;
    },
    focusCustomerSearchInput() {
      let actionType = "CheckoutCart - focusCustomerSearchInput";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      this.$refs.searchCustomerInput.focus();

      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    async isCardIdValid() {
      if (this.loyaltyCardId && !this.loyaltyCardId.startsWith("100")) {
        if (!this.customer || (this.customer && this.customer["CardId"] !== this.loyaltyCardId)) {
          return customersServices.getByCardId(this.loyaltyCardId).then(
            (result) => {
              return result.count !== 0;
            },
            // eslint-disable-next-line
            (error) => {
              return false;
            }
          );
        }
      }
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },
    isCustomerInfoEnabled() {
      // @modified ana.castro 2021-07-16 SABO-2089
      return this.cartType !== "return" && this.cartType !== "returnTotal";
    },
    closeCustomerDialog: function () {
      this.displayCustomerDataDialog = false;
    },
    createCustomer: function (customerData) {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      this.displayCustomerDataDialog = false;

      customersServices.setCustomer(customerData).then(
        (result) => {
          if (result.status === "1") {
            customersServices.get(customerData.telephone).then(
              (result) => {
                if (result.count > 0) {
                  this.displayButtonAddCustomer = false;
                  this.displayCustomerInfo = true;
                  this.customer = result.results[0];
                  this.taxNumber =
                    this.customer["FederalTaxId"] && this.customer["FederalTaxId"].toUpperCase().startsWith("PT")
                      ? this.customer["FederalTaxId"].toUpperCase().replace("PT", "")
                      : this.customer["FederalTaxId"];
                  this.loyaltyCardId = this.customer["CardId"];
                  this.customerName = this.customer["OrganizationName"];
                  this.$store.dispatch("loader/setLoading", false);
                } else {
                  this.$store.dispatch("loader/setLoading", false);
                  this.$store.dispatch(
                    "alert/error",
                    this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                      " " +
                      this.$vuetify.lang.t("$vuetify.errorRegisterClient")
                  );
                }
              },
              (error) => {
                getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorGettingCustomerDetail"), this);
              }
            );
          } else {
            this.$store.dispatch(
              "alert/error",
              this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                " " +
                this.$vuetify.lang.t("$vuetify.errorRegisterClient")
            );
          }
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
              " " +
              this.$vuetify.lang.t("$vuetify.errorRegisterClient")
          );
          getErrorMessage(error, "Set Customer", this);
        }
      );
    },
    closeSaleInvoiceDataDialog: function () {
      this.displaySaleInvoiceDataDialog = false;
    },
    setSaleInvoiceData: function (saleInvoiceData) {
      this.saleInvoiceData = saleInvoiceData;
      this.displaySaleInvoiceDataDialog = false;
    },
    closeDialogCampaigns: function () {
      this.displayDialogLoyaltyCampaigns = false;
    },
    /**
     * This function applies the Discounts (value and percentage) defined by the selected Campaigns the user has
     * access to.
     * Prior to assigning any Campaign to the Cart, the Campaigns which might already be assigned are removed. This means
     * removing the identifiers of the Campaigns and the discounts assigned to the Cart's Items.
     **/
    addCampaign: function (loyaltyCampaigns) {
      let discountValue = 0;
      let discountType = null;

      //Everytime a Campaign is added, the Campaigns which might be applied are removed:
      //1 - the Campaigns stored on the Cart store -> appliedLoyaltyCampaigns
      //2 -the discounts applied to the Cart's Items -> discount_loyalty
      this.$store.dispatch("cart/removeCartDiscountLoyalty");

      //When no Loyalty Campaign is selected, all the Discounts applied must be removed
      if (loyaltyCampaigns.length > 0) {
        //The Discounts applied must be only of one type: "value" or "percent";
        //The dialog where the Loyalty Campaigns are selected is supposed to allow selecting multiple Loyalty Campaigns
        // but of the same type.
        //Therefore, to identify the type of the Discount, it is used the first Loyalty Campaign of the list
        if (loyaltyCampaigns[0].value) {
          discountType = "value";
        } else {
          discountType = "percentage";
        }

        //Iterating through all the selected Loyalty Campaigns and adding its value to the total Discount Value
        for (var i = 0; i < loyaltyCampaigns.length; i++) {
          this.customerSelectedLoyaltyCampaigns.push(loyaltyCampaigns[i].id);
          if (discountType === "value" && loyaltyCampaigns[i].value) {
            discountValue += loyaltyCampaigns[i].value;
          } else if (discountType === "percentage" && loyaltyCampaigns[i].percent) {
            discountValue += loyaltyCampaigns[i].percent;
          }
        }

        //Setting the Discount to the Cart's Items
        this.$store.dispatch("cart/setCartLoyaltyCampaignDiscount", {
          discountValue: discountValue,
          discountType: discountType,
        });

        //Assigning to the Cart store "appliedLoyaltyCampaigns" the identifiers of the selected Campaigns
        this.$store.dispatch("cart/setLoyaltyCampaigns", loyaltyCampaigns);
      }

      this.displayDialogLoyaltyCampaigns = false;
    },
    modifyPaymentMethodValue(data) {
      this.paymentMethodsValues.splice(data.index, 1, JSON.parse(JSON.stringify(data.value)));
    },
    modifyChangePaymentMethodValue(data) {
      this.changePaymentMethodsValues.splice(data.index, 1, JSON.parse(JSON.stringify(data.value)));
    },
    addPaymentMethod() {
      this.paymentMethodsValues.push({
        value: displayWithCents(round2Decimals(this.totalUnpaid)),
        // value: parseFloat(this.totalUnpaid).toFixed(2),
      });
    },
    removePaymentMethod(index) {
      this.paymentMethodsValues.splice(index, 1);
      for (let paymentMethodValue of this.paymentMethodsValues) {
        paymentMethodValue.child = false;
      }
    },
    getPaymentMethods() {
      let actionType = "CheckoutCart - getPaymentMethods";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      if (this.cartType !== "return" && this.cartType !== "returnTotal") {
        actionValue = "paymentTypes/getAll - Local Getter - Start";
        this.$store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
        });
        this.$store.getters["paymentTypes/getAll"].then((result) => {
          this.paymentMethods = result;
        });
        actionValue = "paymentTypes/getAll - Local Getter - End";
        this.$store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
        });
      } else {
        this.$store.getters["paymentTypes/getUseInReturnDocument"].then((result) => {
          this.paymentMethods = result;
        });
      }

      actionValue = "paymentTypes/getTypeCash - Local Getter - Start";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      this.$store.getters["paymentTypes/getTypeCash"].then((result) => {
        this.changePaymentMethods = result;
        this.changePaymentMethodsOriginal = result;
      });
      actionValue = "getter paymentTypes/getTypeCash - Local Getter - End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    //
    async submit() {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      if ((await this.isCardIdValid()) === false) {
        this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorInvalidLoyaltyCard"));
        this.$store.dispatch("loader/setLoading", false);
        return;
      } else if (this.cartItems.length === 0) {
        this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorNoSaleItems"));
        this.$store.dispatch("loader/setLoading", false);
        return;
      } else if (this.paymentMethodsValues.length > 0 && !this.paymentMethodAutoValue) {
        for (let i = 0; i < this.paymentMethodsValues.length; i++) {
          let paymentMethod = this.paymentMethods.find((element) => element.id === this.paymentMethodsValues[i].method);
          if (paymentMethod.data["PaymentTypeAuto"] === "1") {
            this.displayPaymentMethodAutoDialog = true;
            this.displayPaymentMethodAutoDialogTitle = paymentMethod.data["TenderName"];
            this.paymentMethodAutoType = paymentMethod.data["PaymentTypeAuto"];
            this.paymentMethodAuto = this.paymentMethodsValues[i].method;

            this.$store.dispatch("loader/setLoading", false);

            return;
          }
        }
      }
      //When the amount paid by the Customer is higher than the Sale value, it is necessary to include on the
      //  Payment Methods the Change value.

      //The Change value is given to the Customer on the same Payment Method used by the Customer.
      //  Note: this rule only applies when a single Payment Method was used.
      if (
        this.paymentMethodsValues.length === 1 &&
        // parseFloat( (this.cartTotalAmount - this.totalPaymentMethods).toFixed(2) ) < 0
        round2Decimals(this.cartTotalAmount - this.totalPaymentMethods) < 0
      ) {
        this.paymentMethodsValues.push({
          value: displayWithCents(this.cartTotalAmount - this.totalPaymentMethods),
          method: this.paymentMethodsValues[0].method,
          child: true,
        });
      }

      // The Change value is given to the Customer on the Payment Method the Salesman selected as being the method
      //  used to give the change.
      //  Note: this rule only applies when more than a Payment Method was used.
      else if (
        this.paymentMethodsValues.length > 1 &&
        // (this.cartTotalAmount - this.totalPaymentMethods).toFixed(2) < 0 &&
        round2Decimals(this.cartTotalAmount - this.totalPaymentMethods) < 0 &&
        this.changePaymentMethodsValues.length === 1
      ) {
        this.paymentMethodsValues.push({
          // value: (this.cartTotalAmount - this.totalPaymentMethods).toFixed(2),
          value: displayWithCents(this.cartTotalAmount - this.totalPaymentMethods),
          method: this.changePaymentMethodsValues[0].method,
          child: true,
        });
      }

      // 2 - an object is defined with all the information which will be passed to the backend
      let obj = {
        items: this.cartItems,
        paymentMethods: this.paymentMethodsValues,
        store: this.$route.params.store,
        taxNumber:
          this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
            ? this.taxNumber.toUpperCase().replace("PT", "")
            : this.taxNumber,
        loyaltyCardId: this.loyaltyCardId,
        customerId: this.customer ? this.customer["CustomerId"] : null,
        customerName: this.customerName,
        comments: this.comments,
        documentType: this.$store.getters["docTypes/getActiveSale"](),
        salesman: this.selectedSalesman.id,
        cashDrawer: this.selectedCashDrawer,
        salesPricesRangeCode: this.cartSalesPricesRangeCode,
        //@modified ana.castro 2022.12.05 SAFO-47
        zone: this.cartZone ? this.cartZone.code : null,
        rescuedLoyaltyCampaigns: this.customerSelectedLoyaltyCampaigns,
        saleInvoiceData: this.saleInvoiceData,
        vatExempt: this.$store.state.cart.activeCart.vatExempt,
        salesExtensionAttributes: this.modelExtensionFieldsForSalesValues,
        hash: this.$store.state.cart.activeCart.hash,
      };

      salesServices.setSale(obj).then(
        (result) => {
          if (this.printPageSize === "1" && result["PrintRollUrl"]) {
            this.displaySaleDocument = true;
            this.saleDocumentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
            this.saleDocumentSourceAppend = getApiBaseUrl() + result["PrintOfferRollUrl"].substring(1);
          } else if (this.printPageSize === "2" && result["PrintA4Url"]) {
            this.displaySaleDocument = true;
            this.saleDocumentSource = getApiBaseUrl() + result["PrintA4Url"].substring(1);
            this.saleDocumentSourceAppend = getApiBaseUrl() + result["PrintOfferA4Url"].substring(1);
          } else {
            this.$store.dispatch("cart/setCartEmpty");
            //Redirecting the user to the Catalog
            this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
          }
          this.paymentMethodsValues = [];
          this.paymentMethodAutoValue = undefined;
          this.$store.dispatch("loader/setLoading", false);
        },
        (error) => {
          this.paymentMethodAutoValue = undefined;
          this.$store.dispatch(
            "alert/error",
            this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
              " " +
              this.$vuetify.lang.t("$vuetify.errorRegisterSale")
          );
          getErrorMessage(error, "Set Customer Purchase", this);
        }
      );
    },
    submitReturn() {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      if (this.cartItems.length === 0) {
        this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorNoSaleItems"));
        this.$store.dispatch("loader/setLoading", false);
      } else {
        // 2 - an object is defined with all the information which will be passed to the backend
        let obj = {
          items: this.cartItems,
          paymentMethods: this.paymentMethodsValues,
          store: this.$route.params.store,
          taxNumber:
            this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
              ? this.taxNumber.toUpperCase().replace("PT", "")
              : this.taxNumber,
          loyaltyCardId: this.loyaltyCardId,
          customerId: this.customer ? this.customer["Id"] : null,
          customerName: this.customerName,
          comments: this.comments,
          documentType: this.$store.getters["docTypes/getActiveReturn"](),
          salesman: this.selectedSalesman.id,
          salesPricesRangeCode: this.cartSalesPricesRangeCode,
          refDocument: this.cartRefDocument,
          cashDrawer: this.selectedCashDrawer,
          hash: this.$store.state.cart.activeCart.hash,
        };

        //Before creating a NC to the Sales Document it is necessary to verify if there isn't already a NC assigned
        // to the Sales Document
        let params = { InvoiceNo: this.cartRefDocument };
        salesServices.getAll(params).then(
          (result) => {
            if (
              isValidSaleDocType(result.results[0].TransNature) &&
              (result.results[0].TotalAmountCredit < result.results[0].TotalAmount ||
                result.results[0].TotalAmount === 0)
            ) {
              salesServices.setSale(obj).then(
                (result) => {
                  if (this.printPageSize === "1" && result["PrintRollUrl"]) {
                    this.displaySaleDocument = true;
                    this.saleDocumentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
                  } else if (this.printPageSize === "2" && result["PrintA4Url"]) {
                    this.displaySaleDocument = true;
                    this.saleDocumentSource = getApiBaseUrl() + result["PrintA4Url"].substring(1);
                  } else {
                    this.$store.dispatch("cart/setCartEmpty");
                    //Redirecting the user to the Catalog
                    this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
                  }
                  this.$store.dispatch("loader/setLoading", false);
                },
                (error) => {
                  getErrorMessage(error, "Set Customer Purchase", this);
                }
              );
            } else {
              this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorCreditNotesIssue"));
            }

            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            getErrorMessage(error, "Getting Search Results", this);
          }
        );
      }
    },
    async printAndSave() {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      if ((await this.isCardIdValid()) === false) {
        this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorInvalidLoyaltyCard"));
        this.$store.dispatch("loader/setLoading", false);
      } else if (this.cartItems.length === 0) {
        this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorNoSaleItems"));
        this.$store.dispatch("loader/setLoading", false);
      } else {
        // 2 - an object is defined with all the information which will be passed to the backend
        let obj = {
          items: this.cartItems,
          paymentMethods: this.paymentMethodsValues,
          store: this.$route.params.store,
          taxNumber:
            this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
              ? this.taxNumber.toUpperCase().replace("PT", "")
              : this.taxNumber,
          loyaltyCardId: this.loyaltyCardId,
          customerId: this.customer ? this.customer["Id"] : null,
          customerName: this.customerName,
          comments: this.comments,
          documentType: "OR",
          documentNature: "OR",
          documentDescription: "Orçamento",
          documentNatureDescription: "Orçamento",
          documentUseInSales: 0,
          transStockManagement: 0,
          salesman: this.selectedSalesman.id,
          salesPricesRangeCode: this.cartSalesPricesRangeCode,
          cashDrawer: this.selectedCashDrawer,
          hash: this.$store.state.cart.activeCart.hash,
        };

        salesServices.setSale(obj).then(
          (result) => {
            if (result["InvoiceNo"]) {
              this.documentIdentifier = result["InvoiceNo"];
            }
            if (this.printPageSize === "1" && result["PrintRollUrl"]) {
              this.displayBudgetDocument = true;
              this.saleDocumentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
            } else if (this.printPageSize === "2" && result["PrintA4Url"]) {
              this.displayBudgetDocument = true;
              this.saleDocumentSource = getApiBaseUrl() + result["PrintA4Url"].substring(1);
            } else {
              let customer = null;

              if (this.taxNumber || this.loyaltyCardId || this.customerName) {
                customer = {
                  taxNumber:
                    this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
                      ? this.taxNumber.toUpperCase().replace("PT", "")
                      : this.taxNumber,
                  loyaltyCardId: this.loyaltyCardId,
                  name: this.customerName,
                };
              }
              let extraIdentifier = this.documentIdentifier;
              let selectedSalesman = this.selectedSalesman.data.SalesmanShortName;
              this.$store.dispatch("cart/saveActiveCart", {
                customer,
                extraIdentifier,
                selectedSalesman,
              });
              //Redirecting the user to the Catalog
              this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
            }
            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            this.$store.dispatch(
              "alert/error",
              this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                " " +
                this.$vuetify.lang.t("$vuetify.errorRegisterBudget")
            );
            getErrorMessage(error, "Set Customer Purchase Orçamento", this);
          }
        );
      }
    },
    isSubmitEnabled() {
      let actionType = "CheckoutCart - isSubmitEnabled";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      let flagSubmitBlocked = false;
      //
      let totalAmountPaymentMethodsNoCash = 0;

      if (!this.cartZone.selfexpenditure) {
        //1 - If the Payment Methods' Total Value totals the Total Amount of the Cart
        // it is necessary to validate if all the Payment Methods are identified, otherwise the
        // button to Finish the Cart must be disabled.
        //2 - If the Payment Methods' Total Value does not total the Total Amount of the Cart the
        // button to Finish the Cart must be disabled.
        if (parseFloat(this.totalPaymentMethods) >= parseFloat(this.cartTotalAmount)) {
          //Setting the Vuex store's value to determine whether the buttons to Finish the Cart are enabled
          this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
          for (let i = 0; i < this.paymentMethodsValues.length; i++) {
            if (!("method" in this.paymentMethodsValues[i])) {
              //Setting the Vuex store's value to determine whether the buttons to Finish the Cart are enabled
              this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
              flagSubmitBlocked = true;
              break;
            } else {
              //Vamos obter a informação completa do Método de Pagamento
              //  para validar se é um Método de Pagamento que permite dar Troco
              this.$store.getters["paymentTypes/get"](this.paymentMethodsValues[i]["method"]).then((result) => {
                let paymentMethod = result[0];

                //Confirmado que é um Método de Pagamento que não permite dar Troco,
                //  o seu valor é somado ao totalizador que regista o Valor pago em Métodos de Pagamento
                //  que não permitem dar Troco
                if (paymentMethod.data["TenderType"] !== 1) {
                  totalAmountPaymentMethodsNoCash += parseFloat(this.paymentMethodsValues[i]["value"]);

                  // Caso o somatório do Valor de Métodos de Pagamento que não permitem dar Troco, seja superior ao Valor
                  // total da Compra não é possível finalizar a compra
                  if (
                    // parseFloat(totalAmountPaymentMethodsNoCash.toFixed(2)) >
                    round2Decimals(totalAmountPaymentMethodsNoCash) >
                    // parseFloat(this.cartTotalAmount)
                    round2Decimals(this.cartTotalAmount)
                  ) {
                    this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
                  }
                }
              });
            }
          }

          for (let j = 0; j < this.changePaymentMethodsValues.length; j++) {
            if (!("method" in this.changePaymentMethodsValues[j])) {
              //Setting the Vuex store's value to determine whether the buttons to Finish the Cart are enabled
              this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
              flagSubmitBlocked = true;
            }
          }
        } else {
          //Setting the Vuex store's value to determine whether the buttons to Finish the Cart are enabled
          this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
          flagSubmitBlocked = true;
        }

        //If no Tax Number is identified and the Cart's Total Amount is greater than 1000,
        // the submit button might be blocked.
        if (!flagSubmitBlocked) {
          if (parseFloat(this.cartTotalAmount) > 1000 && !this.taxNumber) {
            this.$refs.taxNumberInput.validate(true);
            this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
            flagSubmitBlocked = true;
          } else {
            this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
          }
        }

        //If a Tax Number is identified and it is invalid, the submit button might be blocked.
        if (!flagSubmitBlocked && this.taxNumber) {
          if (!this.taxNumberRules.every((r) => r(this.taxNumber) === true)) {
            this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
            flagSubmitBlocked = true;
          } else {
            this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
          }
        }
      } else {
        this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
      }

      if (!flagSubmitBlocked) {
        if (this.cartType === "return" || this.cartType === "returnTotal") {
          this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
        } else {
          for (let field of this.modelExtensionFieldsForSalesSelect) {
            if (field.required && !this.modelExtensionFieldsForSalesValues[field.data["Column"]]) {
              this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "blocked");
              flagSubmitBlocked = true;
              break;
            } else {
              this.$store.dispatch("cart/setCheckoutCartSubmitStatus", "available");
            }
          }
        }
      }
      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    closeDocument: function () {
      if (this.displaySaleDocument) {
        this.$store.dispatch("cart/setCartEmpty");
        //Redirecting the user to the Catalog
        this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
        this.displaySaleDocument = false;
      } else if (this.displayBudgetDocument) {
        let customer = null;

        if (this.taxNumber || this.loyaltyCardId || this.customerName) {
          customer = {
            taxNumber:
              this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
                ? this.taxNumber.toUpperCase().replace("PT", "")
                : this.taxNumber,
            loyaltyCardId: this.loyaltyCardId,
            name: this.customerName,
          };
        }
        let extraIdentifier = this.documentIdentifier;
        let selectedSalesman = this.selectedSalesman.data.SalesmanShortName;
        this.$store.dispatch("cart/saveActiveCart", {
          customer,
          extraIdentifier,
          selectedSalesman,
        });
        //Redirecting the user to the Catalog
        this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
        this.displayBudgetDocument = false;
      }
    },
    getDefaultCashDrawers() {
      let actionType = "CheckoutCart - getDefaultCashDrawers";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      actionValue = "cashDrawers/getDefaultCashDrawer - Local Getter - Start";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      this.$store.dispatch("cashDrawers/getDefaultCashDrawers").then((result) => {
        this.cashDrawers = result;
        if (this.cashDrawers.length === 1) {
          this.selectedCashDrawer = this.cashDrawers[0].tillId;
        }
      });
      actionValue = "cashDrawers/getDefaultCashDrawer - Local Getter - End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    /**
     * The Cart has the option "Guardar" which can be triggered when the Sale is being finished.
     * In this case, an event from the Cart is emitted to this component and to this specific method.
     *
     * 2.1 adds the Cart to the Saved Carts
     * 2.2 removes the Salesman assigned to the Sale
     * 2.3 redirects the User to the Catalog interface
     */
    cancelAndSaveCart() {
      let customer = null;

      if (this.taxNumber || this.loyaltyCardId || this.customerName) {
        customer = {
          taxNumber:
            this.taxNumber && this.taxNumber.toUpperCase().startsWith("PT")
              ? this.taxNumber.toUpperCase().replace("PT", "")
              : this.taxNumber,
          loyaltyCardId: this.loyaltyCardId,
          name: this.customerName,
        };
      }
      let extraIdentifier = this.comments;
      let selectedSalesman = this.selectedSalesman.data.SalesmanShortName;
      // 2.1 adds the Cart to the Saved Carts
      this.$store.dispatch("cart/saveActiveCart", {
        customer,
        extraIdentifier,
        selectedSalesman,
      });
      // 2.2 removes the Salesman assigned to the Sale
      this.$store.dispatch("salesmen/setSelectedSalesman", undefined);

      // 2.3 Redirects the User to the Catalog interface
      if (this.$router.currentRoute.name !== "catalog") {
        this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
      }
    },
    getAllCountries() {
      this.$store.getters["countries/getAll"].then(
        (result) => {
          this.countries = result;
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorGettingCountries"));
        }
      );
    },
    countryOptions() {
      var returnOptions = [];

      for (var i = 0; i < this.countries.length; i++) {
        returnOptions.push({ text: this.countries[i]["name"], value: this.countries[i]["ISOCountryId"] });
      }

      return returnOptions;
    },
    //@modified ana.castro 2023.07.03 SAFO-72
    /**
     * Closing the dialog which allows the user to give extra information regarding Payment Methods
     */
    closePaymentMethodAutoDialog() {
      this.displayPaymentMethodAutoDialog = false;
      this.paymentMethodAuto = undefined;
      this.paymentMethodAutoType = undefined;
      this.paymentMethodAutoValue = undefined;
    },
    //@modified ana.castro 2023.07.03 SAFO-72
    /**
     * The Payment Method may need the user to fill extra information.
     * This procedure is used to validate the extra information. In case, it is successfully validated,
     * the sale is automatically closed.
     */
    validatePaymentMethodAuto() {
      if (this.paymentMethodAutoValue) {
        let parameters = {
          PaymentMethod: this.paymentMethodAuto,
          PaymentTypeAuto: this.paymentMethodAutoType,
          PaymentTypeAutoIdentifier: this.paymentMethodAutoValue.trim(),
        };

        paymentTypesService.validatePaymentMethodAuto(parameters).then(
          // eslint-disable-next-line
          (result) => {
            this.submit();
            this.displayPaymentMethodAutoDialog = false;
            this.$store.dispatch("loader/setLoading", false);
          },
          (error) => {
            this.displayPaymentMethodAutoDialog = false;
            this.paymentMethodAutoValue = undefined;
            getErrorMessage(error, "Validating Automatic Payment Method", this);
          }
        );
      } else {
        this.displayPaymentMethodAutoDialog = true;
        this.paymentMethodAutoValue = undefined;
      }
    },
  },
  computed: {
    totalPaymentMethods: function () {
      let totalPaymentMethodsValue = this.paymentMethodsValues
        .filter((a) => !isNaN(parseFloat(a.value)) && a.method)
        .reduce((a, b) => a + parseFloat(b.value), 0);
      // return totalPaymentMethodsValue.toFixed(2);
      return displayWithCents(totalPaymentMethodsValue);
    },
    totalUnpaid: function () {
      return this.cartTotalAmount - this.totalPaymentMethods;
    },
    displayDocument: function () {
      return this.displaySaleDocument || this.displayBudgetDocument;
    },
    taxNumberRules: function () {
      let errors = [];

      //Only digits: has to be length 9 and validateTaxNumberPT
      //Starts with PT/pt:  has to be length 11 and validateTaxNumberPT
      //Another formats: no validations are applied
      errors.push((v) => {
        if (!v) {
          if (parseFloat(this.cartTotalAmount) > 1000) {
            return this.$vuetify.lang.t("$vuetify.mandatoryField");
          } else {
            return true;
          }
        } else if (
          ((/^\d+$/.test(v) && v.length === 9) || (/^PT\d+$/i.test(v) && v.length === 11)) &&
          !validateTaxNumberPT(v.replace("PT", "").replace("pt", ""))
        ) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        } else if ((/^\d+$/.test(v) && v.length !== 9) || (/^PT\d+$/i.test(v) && v.length !== 11)) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN9");
        } else if (v.length < 3) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        }

        return true;
      });

      return errors;
    },
    ...mapGetters({
      cartTotalAmount: "cart/cartTotalAmount",
      cartItems: "cart/cartItems",
      cartType: "cart/cartType",
      cartRefDocument: "cart/cartRefDocument",
      cartTaxNumber: "cart/cartTaxNumber",
      cartLoyaltyCardId: "cart/cartLoyaltyCardId",
      cartCustomerName: "cart/cartCustomerName",
      cartSalesPricesRangeCode: "cart/cartSalesPricesRange",
      cartZone: "cart/cartZone",
      currencySymbol: "configs/currencySymbol",
      selectedSalesman: "salesmen/getSelectedSalesman",
      redirectPostSelectSalesmanParameters: "salesmen/getRedirectPostSelectSalesmanParameters",
      printPageSize: "configs/printPageSize",
    }),
  },
  watch: {
    paymentMethodsValues() {
      let actionType = "CheckoutCart - paymentMethodsValues";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      let totalAmountPaymentMethodsCash = 0;

      let validChangePaymentMethods = [];

      this.changePaymentMethods = [];

      //No caso do Valor dos Métodos de Pagamento ser superior ao Valor a Pagar,
      // é necessário identificar o/os método(s) disponíveis para dar o Troco.
      if (parseFloat(this.totalPaymentMethods) > parseFloat(this.cartTotalAmount)) {
        this.changePaymentMethodsValues = [];
        //Iteramos sob todos os Métodos de Pagamento utilizados para Pagar a compra
        // e verificamos quais deles permitem dar Troco.
        for (let i = 0; i < this.paymentMethodsValues.length; i++) {
          if (this.paymentMethodsValues[i]["method"]) {
            //Vamos obter a informação completa do Método de Pagamento
            //  para validar se é um Método de Pagamento que permite dar Troco
            this.$store.getters["paymentTypes/get"](this.paymentMethodsValues[i]["method"]).then((result) => {
              let paymentMethod = result[0];

              //Confirmado que é um Método de Pagamento que permite dar Troco,
              //  o Método é adicionado à listagem de Identificadores de Métodos quer permitem dar Troco
              if (paymentMethod.data["TenderType"] === 1) {
                // validChangePaymentMethods = [1, 2] (respetivamente Dinheiro e Numerário)
                validChangePaymentMethods.push(this.paymentMethodsValues[i]["method"]);

                //É necessário contabilizar o Valor pago a mais utilizando Métodos de Pagamento que permitem dar Troco
                totalAmountPaymentMethodsCash += parseFloat(this.paymentMethodsValues[i]["value"]);

                //Para preencher automaticamente o Método de Pagamento em que será dado o Troco e o respetivo Valor:
                // valor = Valor pago a mais utilizando Métodos de Pagamento que permitem dar Troco
                //        - Valor a Pagar
                //método de pagamento = último Método de Pagamento utilizado e que permite dar Troco
                this.changePaymentMethodsValues = [
                  {
                    // value: parseFloat((totalAmountPaymentMethodsCash - this.cartTotalAmount).toFixed(2)),
                    value: round2Decimals(totalAmountPaymentMethodsCash - this.cartTotalAmount),
                    method: validChangePaymentMethods[validChangePaymentMethods.length - 1],
                  },
                ];

                //Por último, é necessário filtrar nos Métodos de Pagamento que permitem dar Troco,
                // os Métodos que são utilizados na Compra.
                // Exemplo:
                // métodos de pagamento que permite dar Troco: Dinheiro e Numerário
                // métodos de pagamento utilizados na Compra: Dinheiro
                // Para seleção do Método de Pagamento em que será dado o Troco, apenas o Dinheiro estará disponível
                for (let j = 0; j < this.changePaymentMethodsOriginal.length; j++) {
                  if (validChangePaymentMethods.includes(this.changePaymentMethodsOriginal[j].id)) {
                    this.changePaymentMethods.push(this.changePaymentMethodsOriginal[j]);
                  }
                }
              }
            });
          }
        }
      } else if (this.totalPaymentMethods < this.cartTotalAmount && this.changePaymentMethodsValues.length > 0) {
        this.changePaymentMethodsValues = [];
      }

      //Performing the Validation related to the buttons to Finish the Cart
      this.isSubmitEnabled();
      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    changePaymentMethodsValues() {
      let actionType = "CheckoutCart - changePaymentMethodsValues";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      //Performing the Validation related to the buttons to Finish the Cart
      this.isSubmitEnabled();
      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    taxNumber() {
      let actionType = "CheckoutCart - taxNumber";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
      //Performing the Validation related to the buttons to Finish the Cart
      this.isSubmitEnabled();
      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
    /**
     * This watch is needed because the Cart's Total Amount can be changed by the selection of Loyalty Campaigns.
     * Therefore, the default value assign to the Payment Methods must be automatically updated.
     * This update is automatically applied, only if there is just one Payment Method Value.
     */
    cartTotalAmount() {
      let actionType = "CheckoutCart - cartTotalAmount";
      let actionValue = "Start";
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      if (this.paymentMethodsValues.length === 1 && this.paymentMethodsValues[0].value !== this.cartTotalAmount) {
        this.paymentMethodsValues = [{ value: this.cartTotalAmount }];
      }

      actionValue = "End";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });
    },
  },
  beforeDestroy() {
    // Remove all listening events. When this component is referenced multiple times, all referenced listeners are removed
    this.$root.$off("submitCart");
    this.$root.$off("submitCartPrintAndSave");
    this.$root.$off("cancelAndSaveCart");

    //
    this.customerLoyaltyCampaigns = [];
    this.customerLoyaltyCampaignsValue = [];
    this.customerLoyaltyCampaignsPercent = [];
    this.customerSelectedLoyaltyCampaigns = [];

    //The Campaigns which might be applied are removed:
    //1 - the Campaigns stored on the Cart store -> appliedLoyaltyCampaigns
    //2 -the discounts applied to the Cart's Items -> discount_loyalty
    this.$store.dispatch("cart/removeCartDiscountLoyalty");

    // TODO comentar
    this.customer = { OrganizationName: "Cliente Final" };
    this.taxNumber = undefined;
    this.customerName = null;
    this.searchText = "";
  },
  mounted() {
    this.defineCustomer();
    let actionType = "CheckoutCart - Mounted";
    let actionValue = "Start";
    let actionData = {};
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    this.labelPaymentMethod = this.$vuetify.lang.t("$vuetify.paymentMethod");
    this.labelChangePaymentMethod = this.$vuetify.lang.t("$vuetify.change");

    //When the user accesses the interface to finish the Sale process, in case it is a Sale and not a Return,
    // the application kindly puts the cursor on the input related to the Client's information, enabling the user to
    // automatically identify the Client.
    if (this.cartType !== "return" && this.cartType !== "returnTotal") {
      this.$nextTick(() => {
        this.focusCustomerSearchInput();
      });
    }

    if (this.paymentMethodsValues) {
      //console.log("1");
      this.paymentMethodsValues.push({ value: this.cartTotalAmount });
    }

    // In a Return process, it is not possible to link a Client to the Document.
    // The Client is inherited from the corresponding Sale's Document (if the Sale had a Client).
    if (this.cartType === "return" || this.cartType === "returnTotal") {
      this.customer = null;
    }

    //The Client's Tax Number is Stored in two moments:
    //1 - when a Credit Note is generated based on a Sale which had a Tax Number
    //2 - when a Sale is generated based on a OR which had a Tax Number
    //In these two cases, the Tax Number is stored on the Storage and here we get it to assign it to the new document
    if (this.cartTaxNumber) {
      this.taxNumber = this.cartTaxNumber;
    }
    // else {
    //   // To trigger the Validation of the TaxNumber field
    //   this.taxNumber = null;
    // }

    //The Client's Loyalty Card Identifier is Stored when a Credit Note is generated based on a Sale which had a Cliente.
    //In this case, the Loyalty Card Identifier is stored on the Storage and here we get it to assign it to the new document
    if (this.cartLoyaltyCardId) {
      customersServices.getByCardTaxNumberMobilePhone(this.cartLoyaltyCardId).then((result) => {
        if (result.count > 0) {
          this.customer = result.results[0];
          this.loyaltyCardId = this.customer["CardId"];
          this.customerName = this.customer["OrganizationName"];
        }
      });
    } else {
      this.loyaltyCardId = null;
    }
    this.getPaymentMethods();
    this.getDefaultCashDrawers();

    actionValue = "modelExtension/getExtensionFieldForModel - Start";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });
    this.$store.dispatch("modelExtension/getExtensionFieldForModel", "SALES").then((result) => {
      this.modelExtensionFieldsForSales = result;
      for (var i = 0; i < this.modelExtensionFieldsForSales.length; i++) {
        if (this.modelExtensionFieldsForSales[i].type === "TABLE") {
          this.modelExtensionFieldsForSalesValues[this.modelExtensionFieldsForSales[i].data["Column"]] = null;
          this.modelExtensionFieldsForSalesSelect.push(this.modelExtensionFieldsForSales[i]);
          if (this.modelExtensionFieldsForSales[i].data["Table"] === "Countries") {
            this.getAllCountries();
            this.countryOptions();
          }
        }
      }
    });
    actionValue = "modelExtension/getExtensionFieldForModel - End";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    //Since the buttons to Finish the Cart are drawn in another Component, it is necessary to implement the
    // listener for events from the Cart component
    actionValue = "Defining Listeners - Start";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });
    this.$root.$on("submitCart", () => {
      if (this.cartType === "return" || this.cartType === "returnTotal") {
        this.submitReturn();
      } else {
        this.submit();
      }
    });
    this.$root.$on("submitCartPrintAndSave", () => {
      this.printAndSave();
    });
    this.$root.$on("cancelAndSaveCart", () => {
      this.cancelAndSaveCart();
    });
    actionValue = "Defining Listeners - End";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });

    actionValue = "End";
    this.$store.dispatch("dataLog/setData", {
      actionType,
      actionValue,
      actionData,
    });
  },
};
</script>

<style scoped></style>
