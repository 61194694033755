<!-- This Vue is used to Open/Close Cash Drawers.
It displays three elements:
1 - the Salesman opening/closing the Cash Drawer
2 - the Cash Drawer being opened/closed
3 - the amount counted on the opening/closing of the Cash Drawer

Prior to opening/closing the Cash Drawer, the backend is called to know the status of the Cash Drawer.
To perform this action the API "pos/cashdrawers/pk/?store=X is being used. Depending on the returned information, the
User may be able to proceed with the action.

To proceed with the opening/closing of the Cash Drawer, the backend is called and the API "pos/cashdrawersmovements/" is used.

After opening/closing the Cash Drawer, the User can print a document which displays the information submitted.
-->
<script src="../plugins/fontawesome/fontawesome-pro/js/brands.js"></script>
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card
        class="pa-1 my-2"
        v-for="(cashDrawer, i) in cashDrawers"
        :key="i"
        :color="cashDrawer.default === 1 ? 'blue lighten-4' : cashDrawer.isOpen === 1 ? 'grey lighten-4' : ''"
      >
        <v-card-title>{{ cashDrawer.description }}</v-card-title>

        <v-card-text v-if="cashDrawer.isOpen === 1">
          <div v-if="cashDrawer.data['OpenSalesman']" class="text--primary">{{ cashDrawer.data["OpenSalesman"] }}</div>
          <div v-if="cashDrawer.data['OpenDate']" class="text--primary">
            {{ cashDrawer.data["OpenDate"] }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn v-if="cashDrawer.isOpen === 0" @click="openCloseCashDrawer(cashDrawer)" color="blue" text>
            {{ $vuetify.lang.t("$vuetify.open") }}
          </v-btn>
          <v-btn v-if="cashDrawer.isOpen === 1" @click="openCloseCashDrawer(cashDrawer)" color="red" text>
            {{ $vuetify.lang.t("$vuetify.close") }}
          </v-btn>
          <v-btn
            v-if="cashDrawer.isOpen === 1 && (cashDrawer.default === 0 || !cashDrawer.default)"
            @click="setDefaultCashDrawer(cashDrawer)"
            color="blue"
            text
          >
            {{ $vuetify.lang.t("$vuetify.cashDrawersPage.activateSalesBox") }}
          </v-btn>
          <v-btn @click="searchMovementsCashDrawer(cashDrawer)" color="blue" text>
            {{ $vuetify.lang.t("$vuetify.cashDrawersPage.consult") }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- Dialog to Open and Close the Cashdrawer. It displays all the inputs necessary to these procedures -->
      <v-dialog v-model="displayOpenCloseCashdrawer" persistent transition="dialog-top-transition" max-width="1100">
        <v-container>
          <v-card v-if="selectedCashDrawer" class="mx-auto">
            <v-form>
              <v-card-title v-if="selectedCashDrawer.isOpen === 0" class="text-h6">{{
                $vuetify.lang.t("$vuetify.cashDrawersPage.openCashRegister")
              }}</v-card-title>
              <v-card-title v-else class="text-h6">{{
                $vuetify.lang.t("$vuetify.cashDrawersPage.closeCashRegister")
              }}</v-card-title>

              <v-card-text v-if="selectedCashDrawer.isOpen === 0">
                <v-text-field
                  v-model="pattyCashValue"
                  :label="$vuetify.lang.t('$vuetify.pattyCash')"
                  type="number"
                  :suffix="currencySymbol"
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-text v-else>
                <v-row justify="space-between">
                  <v-col cols="4" md="4">
                    <v-text-field
                      :label="$vuetify.lang.t('$vuetify.cashDrawersPage.initialPattyCash')"
                      v-model="pattyCashValue"
                      type="number"
                      :suffix="currencySymbol"
                      disabled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-checkbox
                      v-model="includesPattyCash"
                      :label="$vuetify.lang.t('$vuetify.cashDrawersPage.includesPattyCash')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" md="2"></v-col>
                </v-row>

                <PaymentMethods
                  v-for="(paymentMethodValue, i) in paymentMethodsValues"
                  :key="i"
                  :paymentMethodValue="paymentMethodValue"
                  :paymentMethods="paymentMethods"
                  :index="i"
                  :drawMinus="paymentMethodsValues.length > 1"
                  :drawPlus="i === paymentMethodsValues.length - 1"
                  :labelPaymentMethod="labelPaymentMethod"
                  @modifyPaymentMethodValue="modifyPaymentMethodValue"
                  @addPaymentMethod="addPaymentMethod"
                  @removePaymentMethod="removePaymentMethod"
                ></PaymentMethods>

                <v-row justify="space-between">
                  <v-col cols="12" md="4">
                    <v-text-field
                      :label="$vuetify.lang.t('$vuetify.cashDrawersPage.pattyCashNextDay')"
                      v-model="pattyCashValueFollowingDay"
                      type="number"
                      :suffix="currencySymbol"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2"></v-col>
                  <v-col cols="12" md="2"></v-col>
                </v-row>
              </v-card-text>

              <v-spacer class="my-5"></v-spacer>

              <div v-if="selectedCashDrawer && selectedCashDrawer.isOpen" class="text-center">
                <v-row justify="center" align="center">
                  <v-col cols="12" md="4">
                    <v-chip label :ripple="false">
                      Total: {{ totalPaymentMethods.toFixed(2) }}
                      {{ currencySymbol }}
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large text @click="cancelOpenCloseCashDrawer()"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
                <v-btn large class="primary" :disabled="!isSubmitEnabled()" @click="submitOpenCloseCashDrawer">
                  {{ $vuetify.lang.t("$vuetify.submit") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-container>
      </v-dialog>

      <!-- TODO comentar     -->
      <v-dialog v-if="selectedCashDrawer" v-model="displayCashDrawerMovementsSearch" scrollable max-width="600px">
        <v-card>
          <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.cashDrawersPage.checkCashMov") }}</v-card-title>
          <CashDrawersSearch
            :cashDrawerCode="selectedCashDrawer.data['TillId']"
            @close-dialog="closeCashDrawerMovementsSearchDialog()"
          >
          </CashDrawersSearch>
        </v-card>
      </v-dialog>

      <!-- TODO comentar     -->
      <v-dialog v-model="displayDialogAlertOpenCashDrawer" persistent max-width="250px">
        <ConfirmAction
          :title="openCashDrawerTitle"
          :confirmMessage="openCashDrawerMessage"
          :displayCancelButton="false"
          @confirm-action="confirmOpenCashDrawer"
        ></ConfirmAction>
      </v-dialog>

      <!-- TODO comentar -->
      <v-dialog v-model="displayDocument" persistent max-width="400px">
        <DisplayPDF :source="documentSource" @close-pdf="closeDocument()"></DisplayPDF>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { cashDrawersService } from "@/services";
import { getApiBaseUrl, getErrorMessage } from "@/_helpers";
import ConfirmAction from "@/components/ConfirmAction.vue";
import DisplayPDF from "@/components/DisplayPDF.vue";
import PaymentMethods from "@/components/PaymentMethods.vue";
import CashDrawersSearch from "@/views/CashDrawersSearch.vue";

export default {
  name: "CashDrawers",
  components: { PaymentMethods, ConfirmAction, DisplayPDF, CashDrawersSearch },
  data: () => ({
    cashDrawers: [],
    displayOpenCloseCashdrawer: false,
    selectedCashDrawer: null,

    paymentMethods: [],
    paymentMethodsValues: [],
    totalValue: 0,
    includesPattyCash: false,
    pattyCashValue: 0,
    pattyCashValueFollowingDay: 0,
    selectedSalesman: null,
    salesmen: [],
    displayDialogAlertOpenCashDrawer: false,
    openCashDrawerTitle: null,
    openCashDrawerMessage: null,
    displayDocument: false,
    documentSource: null,
    labelPaymentMethod: null,
    //
    displayCashDrawerMovementsSearch: false,
  }),
  methods: {
    /**
     * Getting all the CashDrawers saved on the IndexedDB.
     * If there is only one CashDrawer, the option is automatically selected.
     */
    getAllCashDrawers() {
      // this.$store.getters["cashDrawers/getAll"].then((result) => {
      this.$store.dispatch("cashDrawers/getAll").then((result) => {
        this.cashDrawers = result;
      });
    },
    openCloseCashDrawer(cashdrawer) {
      this.displayOpenCloseCashdrawer = true;
      this.selectedCashDrawer = cashdrawer;
    },
    cancelOpenCloseCashDrawer() {
      this.displayOpenCloseCashdrawer = false;
      this.selectedCashDrawer = null;
    },
    setDefaultCashDrawer(cashdrawer) {
      this.$store.dispatch("cashDrawers/setCashDrawerDefault", cashdrawer.id);
      this.getAllCashDrawers();
    },
    searchMovementsCashDrawer(cashdrawer) {
      this.displayCashDrawerMovementsSearch = true;
      this.selectedCashDrawer = cashdrawer;
    },
    closeCashDrawerMovementsSearchDialog() {
      this.displayCashDrawerMovementsSearch = false;
      this.selectedCashDrawer = null;
    },
    /**
     *
     */
    submitOpenCloseCashDrawer: function () {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      // 2 - an object is defined with all the information which will be passed to the backend
      let obj = {
        documentType: this.selectedCashDrawer.isOpen === 0 ? "ACX" : "FCX",
        tillId: this.selectedCashDrawer.data["TillId"],
        includesPattyCash: this.includesPattyCash,
        pattyCash: parseFloat(this.pattyCashValue),
        pattyCashFollowingDay: parseFloat(this.pattyCashValueFollowingDay),
        paymentMethods: this.paymentMethodsValues,
        totalValue: this.selectedCashDrawer.isOpen === 0 ? this.totalValue : this.totalPaymentMethods,
        store: this.$route.params.store,
        salesman: this.activeSalesman.id,
      };

      //First, verifying if the Cash Drawer is not already Opened/Closed
      cashDrawersService.get(this.selectedCashDrawer.id).then(
        (result) => {
          if (
            (!this.selectedCashDrawer.isOpen && result.IsOpen === 0) ||
            (this.selectedCashDrawer.isOpen && result.IsOpen === 1)
          ) {
            cashDrawersService.setCashDrawerMovement(obj).then(
              (result) => {
                if (result["IsOpen"] === 1) {
                  this.selectedCashDrawer.isOpen = 1;
                  this.$store.dispatch("cashDrawers/setCashDrawerOpen", this.selectedCashDrawer.id);
                  if (result["PrintRollUrl"]) {
                    this.displayDocument = true;
                    this.documentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
                  } else {
                    this.$store.dispatch(
                      "alert/success",
                      this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgOpenSuccess")
                    ); //Abertura de Caixa realizada com Sucesso
                    //@modified ana.castro 2022.12.06 SAFO-47
                    // this.$router.push({ name: "catalog" });
                    this.$router.push({ name: "home" });
                  }
                } else {
                  this.selectedCashDrawer.isOpen = 0;
                  this.$store.dispatch("cashDrawers/setCashDrawerClose", this.selectedCashDrawer.id);
                  if (result["PrintRollUrl"]) {
                    this.displayDocument = true;
                    this.documentSource = getApiBaseUrl() + result["PrintRollUrl"].substring(1);
                  } else {
                    this.$store.dispatch(
                      "alert/success",
                      this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgOpenSuccess")
                    ); //Fecho de Caixa realizado com Sucesso
                    this.$router.push({ name: "home" });
                  }
                }
                this.$store.dispatch("loader/setLoading", false);
              },
              (error) => {
                this.$store.dispatch(
                  "alert/error",
                  this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                    " " +
                    this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgMovemNotAvailable")
                );
                getErrorMessage(error, "Set Cash Movement", this);
              }
            );
          } else {
            this.$store.dispatch(
              "alert/error",
              this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
                " " +
                this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgMovemNotAvailable")
            );
            this.$store.dispatch("loader/setLoading", false);
          }
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
              " " +
              this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgMovemNotAvailable")
          );
          getErrorMessage(error, "Get Cash Drawer Data", this);
        }
      );
    },
    isSubmitEnabled() {
      if (this.selectedCashDrawer && !this.selectedCashDrawer.isOpen) {
        //accepting the 0 amount
        // if (!this.totalValue || this.totalValue === 0) {
        if (this.totalValue === "") {
          return false;
        }
      } else {
        if (this.totalPaymentMethods === "") {
          return false;
        }
        for (let i = 0; i < this.paymentMethodsValues.length; i++) {
          if (!("method" in this.paymentMethodsValues[i])) {
            return false;
          }
        }
      }

      return true;
    },

    closeDocument() {
      this.displayDocument = false;
      //@modified ana.castro 2022.12.06 SAFO-47
      // if (this.selectedCashDrawer.isOpen) {
      //   this.$router.push({ name: "catalog" });
      // } else {
      //   this.$router.push({ name: "home" });
      // }
      this.$router.push({ name: "home" });
    },
    /**
     * Getting all the Payment Methods saved on the IndexedDB.
     */
    getAllPaymentMethods() {
      this.$store.getters["paymentTypes/getAll"].then((result) => {
        this.paymentMethods = result;
      });
    },
    /**
     * When the PLUS button is clicked to a new Enter of a Payment Method, the value linked to the Payment Method
     * is initialized with the value 0.
     * For example: [ { "value": 10, "method": 2, "child": true }, { "value": 0 } ]
     */
    addPaymentMethod() {
      this.paymentMethodsValues.push({ value: 0 });
    },
    /**
     * When the MINUS button is clicked to remove a Payment Method enter, the value linked to the Payment Method and the
     * Payment Method must be removed from the paymentMethodsValues list.
     */
    removePaymentMethod(index) {
      this.paymentMethodsValues.splice(index, 1);
      for (let paymentMethodValue of this.paymentMethodsValues) {
        paymentMethodValue.child = false;
      }
    },
    /**
     * Editing the value linked to a Payment Method
     * @param data
     */
    modifyPaymentMethodValue(data) {
      this.paymentMethodsValues.splice(data.index, 1, JSON.parse(JSON.stringify(data.value)));
    },
    /**
     * The user can be redirected to this View when he tries to perform an action for which it is mandatory
     * to have an Open CashDrawer.
     * In these cases, a Dialog is displayed to the User, explaining why he was redirected to this interface.
     * After the User reads the message, he clicks the OK button and it is this function which hides de Dialog.
     */
    confirmOpenCashDrawer() {
      this.displayDialogAlertOpenCashDrawer = false;
    },
  },
  computed: {
    totalPaymentMethods: function () {
      var totalValue = 0;
      totalValue += this.paymentMethodsValues
        .filter((a) => !isNaN(parseFloat(a.value)) && a.method)
        .reduce((a, b) => a + parseFloat(b.value), 0);
      if (!this.includesPattyCash) {
        totalValue += parseFloat(this.pattyCashValueFollowingDay);
      }
      return totalValue;
    },
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
      activeSalesman: "salesmen/getSelectedSalesman",
    }),
  },
  watch: {
    selectedCashDrawer() {
      if (this.selectedCashDrawer && this.selectedCashDrawer.isOpen === 1) {
        cashDrawersService.get(this.selectedCashDrawer.id).then(
          (result) => {
            this.pattyCashValue = result.PattyCash;
          },
          (error) => {
            this.$store.dispatch("alert/error", this.$vuetify.lang.t("$vuetify.msgContentNotAvailable"));
            getErrorMessage(error, this.$vuetify.lang.t("$vuetify.cashDrawersPage.getData"), this);
          }
        );
      }
    },
  },
  mounted() {
    this.getAllCashDrawers();
    this.getAllPaymentMethods();

    this.openCashDrawerTitle = this.$vuetify.lang.t("$vuetify.cashDrawersPage.openCashRegister");
    this.openCashDrawerMessage = this.$vuetify.lang.t("$vuetify.cashDrawersPage.msgOpenClose");
    this.labelPaymentMethod = this.$vuetify.lang.t("$vuetify.paymentMethod");

    //Initializing the Payment Methods options: [ { "value": 0 } ]
    if (this.paymentMethodsValues) {
      this.paymentMethodsValues.push({ value: 0 });
    }

    //The user can be redirected to this View when he tries to perform an action for which it is mandatory
    //to have an Open CashDrawer.
    //In these cases, a Dialog is displayed to the User, explaining why he was redirected to this interface.
    //This is the action which opens the Dialog
    this.displayDialogAlertOpenCashDrawer = this.$route.meta.showModal;
  },
};
</script>

<style scoped></style>
