<template>
  <div id="sale-invoice-data">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $vuetify.lang.t("$vuetify.billingData") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="saleInvoiceDataClone.shipToAddressDetail"
                  :rules="addressRules"
                  :label="$vuetify.lang.t('$vuetify.addressClient')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="saleInvoiceDataClone.shipToAddressPostalCode"
                  :rules="postalCodeRules"
                  :label="$vuetify.lang.t('$vuetify.postalCode')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="saleInvoiceDataClone.shipToAddressCity"
                  :rules="cityRules"
                  :label="$vuetify.lang.t('$vuetify.location')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="saleInvoiceDataClone.shipToAddressCountry"
                  :items="countryOptions()"
                  :label="$vuetify.lang.t('$vuetify.country')"
                  required
                  item-value="value"
                  item-text="text"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
          <v-btn class="primary" text @click="submit" :disabled="!valid">
            {{ $vuetify.lang.t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { getErrorMessage } from "../_helpers";

export default {
  name: "SaleInvoiceData",
  props: {
    saleInvoiceData: Object,
  },
  data: () => ({
    valid: true,
    saleInvoiceDataClone: {},
    countries: [],
  }),
  methods: {
    getAllCountries() {
      this.$store.getters["countries/getAll"].then(
        (result) => {
          this.countries = result;
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorGettingCountries"));
        }
      );
    },
    countryOptions() {
      var returnOptions = [];

      for (var i = 0; i < this.countries.length; i++) {
        returnOptions.push({ text: this.countries[i]["name"], value: this.countries[i]["ISOCountryId"] });
      }

      return returnOptions;
    },
    closeDialog: function () {
      this.saleInvoiceDataClone = this.saleInvoiceData;
      this.$emit("close-dialog");
    },
    submit: function () {
      this.$emit("sale-invoice-data", this.saleInvoiceDataClone);
    },
  },
  computed: {
    /**
     * Validations applied to the field ADDRESS DETAIL:
     * (- mandatory)
     * - can't have more than 200 characters
     * @returns {Array} an array of validations
     */
    addressRules: function () {
      return [(v) => (v && v.length <= 200) || this.$vuetify.lang.t("$vuetify.addressMax200")];
    },
    postalCodeRules: function () {
      return [(v) => (v && v.length <= 20) || this.$vuetify.lang.t("$vuetify.postalCodeMax20")];
    },
    cityRules: function () {
      return [(v) => (v && v.length <= 100) || this.$vuetify.lang.t("$vuetify.locationMax100")];
    },
  },
  watch: {
    saleInvoiceData() {
      this.saleInvoiceDataClone = JSON.parse(JSON.stringify(this.saleInvoiceData));
    },
  },
  mounted() {
    this.saleInvoiceDataClone = JSON.parse(JSON.stringify(this.saleInvoiceData));

    this.getAllCountries();
  },
};
</script>

<style scoped></style>
