<!--https://github.com/FranckFreiburger/vue-pdf-->

<!-- The "vue-pdf" has unresolved issues in what concerns displaying and printing multiple pages.
     Therefore, we have found an workaround:
     1 - the <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf> displays the multiple pages
     2 - the hidden section is only used by the Print button-->
<template>
  <v-card>
    <v-card-text style="height: 70vh; overflow: auto" v-if="loadingTask">
      <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf>
      <v-card-subtitle v-if="loadingTask" style="display: none">
        <pdf
          ref="myPdfComponent"
          :src="newSource"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
        ></pdf>
      </v-card-subtitle>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="emit_close" large> {{ $vuetify.lang.t("$vuetify.close") }}</v-btn>
      <v-btn color="blue" text v-if="displayExtraButton" @click="printExtraDocument()">
        {{ sourceExtraButtonName }}
      </v-btn>
      <v-btn v-if="!android" :disabled="!show" text small :href="sourceClone">
        {{ $vuetify.lang.t("$vuetify.download") }}</v-btn
      >
      <v-btn v-if="!android" :disabled="!show" class="primary" large @click="print()">
        {{ $vuetify.lang.t("$vuetify.print") }}</v-btn
      >
      <!-- Se for android faz download do PDF-->
      <v-btn v-if="android" :disabled="!show" class="primary" large :href="sourceClone">
        {{ $vuetify.lang.t("$vuetify.print") }}</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="displayDialogNrCopiesPrint" persistent max-width="300px">
      <v-card>
        <v-card-title class="title">{{ sourceExtraButtonName }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="numberCopiesPrint"
            :label="$vuetify.lang.t('$vuetify.numberOfCopies')"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelPrintDocument"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
          <v-btn class="primary" text @click="confirmPrintExtraDocument()">{{ $vuetify.lang.t("$vuetify.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import pdf from "vue-pdf";
import printJS from "print-js";
import Vue from "vue";

export default {
  props: {
    source: String,
    sourceExtra: String,
    sourceExtraButtonName: String,
    sourceExtraCopies: Boolean,
  },
  components: {
    pdf,
  },
  data() {
    return {
      sourceClone: this.source,
      displayExtraButton: false,
      showSubtitle: false,
      loadingTask: undefined,
      src: undefined,
      numPages: undefined,
      loaded: 0,
      currentPage: 0,
      pageCount: 0,
      displayDialogNrCopiesPrint: false,
      numberCopiesPrint: 1,
      android: false,
      show: true,
    };
  },
  methods: {
    print() {
      this.show = false;
      let requestOptions = {};

      requestOptions = {
        method: "GET",
        url: this.sourceClone,
      };

      let actionType = "DisplayPDF1-Before";
      let actionValue = this.sourceClone;
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      Vue.axios(requestOptions).then(
        (result) => {
          //const pdfBlob = new Blob([result.data], { type: "application/pdf" });
          //const url = URL.createObjectURL(pdfBlob);

          let actionType = "DisplayPDF1-After";
          let actionValue = this.sourceClone;
          let actionData = {};
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });

          let deviceProps = JSON.parse(localStorage.getItem("deviceProps"));
          let device = "printer";
          let prop = "status";
          let status;

          if (deviceProps !== null && device in deviceProps) {
            if (deviceProps[device][prop] !== null && prop in deviceProps[device]) {
              status = deviceProps[device][prop];
            } else status = 0;
          } else {
            status = 0;
          }

          if (status) {
            const urlStatus = "http://127.0.0.1:9090/getstatus&device=1";
            const config = {
              headers: {
                "content-type": "multipart/form-data",
              },
            };

            let actionType = "DisplayPDF2-CheckHPROXY-Before";
            let actionValue = urlStatus;
            let actionData = {};
            this.$store.dispatch("dataLog/setData", {
              actionType,
              actionValue,
              actionData,
            });

            Vue.axios
              .get(urlStatus, config)
              .then((response) => {
                Vue.$log.debug(response);

                let actionType = "DisplayPDF2-CheckHPROXY-After";
                let actionValue = urlStatus;
                let actionData = response;
                this.$store.dispatch("dataLog/setData", {
                  actionType,
                  actionValue,
                  actionData,
                });

                this.printToHardwarePrinter(result.data);
              })
              .catch((error) => {
                Vue.$log.warn(error.message);
                // Faz sentido fazer set a isto?
                //deviceProps[device][prop] = false;
                //localStorage.setItem("deviceProps", JSON.stringify(deviceProps));
                let actionType = "DisplayPDF2-CheckHPROXY-AfterERROR";
                let actionValue = urlStatus;
                let actionData = {};
                this.$store.dispatch("dataLog/setData", {
                  actionType,
                  actionValue,
                  actionData,
                });

                this.printToBrowserPrinter(result.data);
              });
          } else {
            this.printToBrowserPrinter(result.data);
          }
        },
        (error) => {
          Vue.$log.warn(error);
          this.show = true;
        }
      );
    },
    checkHardwarePrinter() {},
    printToHardwarePrinter(blob) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const url = "http://127.0.0.1:9090/senddata/";
      const formData = new FormData();
      formData.append("DEVICE", 1);
      //let base64Encoded = btoa(decodeURIComponent(encodeURIComponent(blob)));
      let base64Encoded = btoa(unescape(encodeURIComponent(blob)));
      formData.append("file", base64Encoded);

      let actionType = "DisplayPDF2-printToHardwarePrinter-Before";
      let actionValue = url;
      let actionData = {};
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      Vue.axios
        .post(url, formData, config)
        .then((response) => {
          let actionType = "DisplayPDF2-printToHardwarePrinter-After";
          let actionValue = url;
          let actionData = response;
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });

          Vue.$log.debug("Response:", response.message);
          this.show = true;
        })
        .catch((error) => {
          let actionType = "DisplayPDF2-printToHardwarePrinter-AfterError";
          let actionValue = url;
          let actionData = "";
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });

          Vue.$log.warn(error.message);
          this.show = true;
        });
    },
    printToBrowserPrinter(blob) {
      let actionType = "DisplayPDF2-printToBrowserPrinter-Before";
      let actionValue = url;
      let actionData = "";
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      const pdfBlob = new Blob([blob], { type: "application/pdf" });
      const url = URL.createObjectURL(pdfBlob);
      printJS(url);

      this.show = true;
    },
    emit_close() {
      //this.$log.info("ALO??");
      this.$emit("close-pdf");
    },
    cancelPrintDocument: function () {
      this.displayDialogNrCopiesPrint = false;
      this.numberCopiesPrint = 1;
    },
    printExtraDocument: function () {
      if (this.sourceExtraCopies) {
        this.displayDialogNrCopiesPrint = true;
      } else {
        this.confirmPrintExtraDocument();
      }
    },
    confirmPrintExtraDocument: function () {
      this.sourceClone = this.sourceExtra + "&ncopies=" + this.numberCopiesPrint;

      this.definePdfSource();

      this.displayDialogNrCopiesPrint = false;
      this.numberCopiesPrint = 1;
      this.displayExtraButton = false;
    },
    definePdfSource: function () {
      this.loadingTask = pdf.createLoadingTask(this.newSource);
      this.src = this.loadingTask;

      this.src.promise.then(
        (pdf) => {
          this.numPages = pdf.numPages;
        },
        (error) => {
          this.$emit("close-pdf");
          this.$store.dispatch(
            "alert/error",
            this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") + " " + "Não foi possível emitir o relatório."
          );
          Vue.$log.warn(error);
        }
      );
    },
  },
  computed: {
    newSource: function () {
      let user = JSON.parse(localStorage.getItem("user"));

      return {
        url: this.sourceClone,
        httpHeaders: {
          "Accept-Language": "pt",
          Authorization: "JWT " + user.access,
        },
        //withCredentials: true,
      };
    },
  },
  mounted() {
    this.sourceClone = this.source;
    this.definePdfSource();

    if (this.sourceExtra) {
      this.displayExtraButton = true;
    }
    if (navigator.userAgent.match(/Android/)) {
      this.android = true;
    }
  },
};
</script>

<style scoped></style>
