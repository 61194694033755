<template>
  <v-layout>
    <v-col cols="12" v-if="savedCarts.length > 0">
      <v-card class="pa-1 my-2" v-for="(cart, i) in savedCarts" :key="i">
        <v-card-title>{{ cart.dateHour }} </v-card-title>

        <v-card-subtitle>
          <div class="text--primary">
            {{ $vuetify.lang.t("$vuetify.total") }} {{ cart.totalAmount }} {{ currencySymbol }}
          </div>
          <div class="text--primary" v-if="cart.salesmanName">
            {{ $vuetify.lang.t("$vuetify.salesmenName") }} {{ cart.salesmanName }}
          </div>
        </v-card-subtitle>
        <v-card-text>
          <div class="text--primary">
            {{ cart.extraIdentifier }}
          </div>
          <div v-if="cart.customer" class="text--primary">
            <b>{{ $vuetify.lang.t("$vuetify.client") }} </b>
            <span v-if="cart.customer.name">{{ cart.customer.name }}</span>
            <span v-if="cart.customer.name && (cart.customer.loyaltyCardId || cart.customer.taxNumber)"> | </span>
            <span v-if="cart.customer.loyaltyCardId"> {{ cart.customer.loyaltyCardId }}</span>
            <span
              v-if="
                (!cart.customer.name && cart.customer.loyaltyCardId) ||
                (cart.customer.name && cart.customer.loyaltyCardId && cart.customer.taxNumber)
              "
            >
              |
            </span>
            <span v-if="cart.customer.taxNumber"> {{ cart.customer.taxNumber }}</span>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="setSavedToCart(i)" color="blue lighten-2" text>
            {{ $vuetify.lang.t("$vuetify.resumeSale") }}
          </v-btn>
          <v-btn @click="cancel(i)" color="grey" text> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>

          <v-spacer></v-spacer>

          <v-btn icon @click="changeShow(i)">
            <v-icon small>{{ show[i] ? "fas fa-arrow-up" : "fas fa-arrow-down" }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show[i]">
            <v-divider></v-divider>

            <v-card-text>
              <ItemsList :items="cart.items" :enableEditions="false" :enableDialogEdition="false"> </ItemsList>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" v-else> {{ $vuetify.lang.t("$vuetify.savedSales") }} </v-col>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import ItemsList from "@/components/ItemsList.vue";

export default {
  name: "SavedCarts",
  components: { ItemsList },
  data: () => ({
    show: [],
  }),
  methods: {
    setSavedToCart(index) {
      this.$store.dispatch("cart/activateSavedCart", index);
      this.$router.push({ name: "catalog", params: { zone: this.cartZone.code } });
    },
    cancel(index) {
      this.$store.dispatch("cart/deleteSavedCart", index);
    },
    changeShow(index) {
      this.show[index] = !this.show[index];
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters({
      savedCarts: "cart/savedShopCarts",
      cartZone: "cart/cartZone",
      currencySymbol: "configs/currencySymbol",
    }),
  },
  mounted() {
    if (this.savedCarts) {
      for (let i = 0; i < this.savedCarts.length; i++) {
        this.show.push(false);
      }
    }
  },
};
</script>

<style scoped></style>
