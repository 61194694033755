var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.campaigns")))]),_c('v-card-text',[_c('v-list',{attrs:{"two-line":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"450"}},[(_vm.loyaltyCampaignsVouchersValue.length > 0)?_c('v-list-item-group',{attrs:{"active-class":"border","multiple":""},model:{value:(_vm.selectedCampaigns),callback:function ($$v) {_vm.selectedCampaigns=$$v},expression:"selectedCampaigns"}},[_vm._l((_vm.loyaltyCampaignsVouchersValue),function(item,index){return [(item.code && item.value)?_c('v-list-item',{key:item.id,attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name + ' (' + item.value.toFixed(2) + _vm.currencySymbol + ') ')}})],1)]}}],null,true)}):_vm._e(),(index < _vm.loyaltyCampaignsVouchersValue.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),(
            _vm.loyaltyCampaignsVouchersValue.length > 0 &&
            (_vm.loyaltyCampaignsVouchersPercent.length > 0 || _vm.loyaltyCampaigns.length > 0)
          )?_c('v-divider',{staticClass:"my-2 grey"}):_vm._e(),(_vm.loyaltyCampaignsVouchersPercent.length > 0)?_c('v-list-item-group',{attrs:{"active-class":"border","multiple":""},model:{value:(_vm.selectedCampaigns),callback:function ($$v) {_vm.selectedCampaigns=$$v},expression:"selectedCampaigns"}},[_vm._l((_vm.loyaltyCampaignsVouchersPercent),function(item,index){return [(item.code && item.percent)?_c('v-list-item',{key:item.id,attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,true)}):_vm._e(),(index < _vm.loyaltyCampaignsVouchersPercent.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),(_vm.loyaltyCampaignsVouchersPercent.length > 0 && _vm.loyaltyCampaigns.length > 0)?_c('v-divider',{staticClass:"my-2 grey"}):_vm._e(),(_vm.loyaltyCampaigns.length > 0)?_c('v-list-item-group',{attrs:{"active-class":"border"},model:{value:(_vm.selectedCampaigns),callback:function ($$v) {_vm.selectedCampaigns=$$v},expression:"selectedCampaigns"}},[_vm._l((_vm.loyaltyCampaigns),function(item,index){return [(!item.code)?_c('v-list-item',{key:item.id,attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,true)}):_vm._e(),(index < _vm.loyaltyCampaigns.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cancel"))+" ")]),_c('v-btn',{staticClass:"primary",attrs:{"large":""},on:{"click":_vm.selectCampaign}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.add"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }