<template>
  <v-form ref="form" v-model="valid" lazy-validation v-if="ready">
    <v-card class="mx-auto">
      <v-card-title>
        <span>{{ itemClone.item_name }} [</span>
        <span v-if="itemClone.item_extra_info"> {{ itemClone.item_extra_info }}]</span>

        <v-btn
          v-if="itemClone.menu_items && Object.keys(itemClone.menu_items).length > 0"
          icon
          @click="editItemMenuChoices(itemClone)"
        >
          <v-icon small>{{ "fas fa-edit" }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>{{ itemClone.item_code }}</v-card-subtitle>

      <v-card-text>
        <v-text-field
          v-model="itemClone.quantity"
          :label="$vuetify.lang.t('$vuetify.quantity')"
          type="number"
          :rules="quantityRules"
          :disabled="isQuantityDisabled"
          required
        ></v-text-field>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="itemClone.discount.value"
              :label="$vuetify.lang.t('$vuetify.discount')"
              type="number"
              :rules="discountRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="itemClone.discount.type"
              :label="$vuetify.lang.t('$vuetify.discountType')"
              :items="discountTypes"
              item-text="text"
              item-value="value"
              required
              v-on:change="$refs.form.validate()"
            ></v-select> </v-col
        ></v-row>

        <v-text-field
          v-model="itemClone.item_unit_price"
          :label="$vuetify.lang.t('$vuetify.unitPrice')"
          type="number"
          :rules="unitPriceRules"
          :disabled="isPriceDisabled"
          required
        ></v-text-field>

        <h3>{{ $vuetify.lang.t("$vuetify.total") }}</h3>
        <h2>{{ lineTotalPrice }}&nbsp;{{ currencySymbol }}</h2>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog" large> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
        <v-btn class="primary" large @click="editItemLine(itemClone)" :disabled="!valid">
          {{ $vuetify.lang.t("$vuetify.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="displayMenuDialog" scrollable content-class="custom-dialog">
      <ItemsMenuLevelsCard
        v-if="displayMenuDialog"
        :item="selectedItem"
        :levels="selectedItem.menuLevels"
        :editItemInCart="true"
        @close-dialog="closeMenuDialog"
      >
      </ItemsMenuLevelsCard>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import ItemsMenuLevelsCard from "@/views/ItemsMenuLevelsCard.vue";
import { round2Decimals, displayWithCents } from "@/_helpers";

export default {
  name: "ItemsListLineEdit",
  components: { ItemsMenuLevelsCard },

  props: {
    item: Object,
  },
  data() {
    return {
      itemClone: Object.assign(
        // { discount: 0, discount_type: "value" },
        JSON.parse(JSON.stringify(this.item))
      ),
      discountTypes: [],
      valid: true,
      ready: false,
      isPriceDisabled: true,
      //A flag to identify if the "Quantity" field is editable or not
      isQuantityDisabled: false,
      //
      displayMenuDialog: false,
      selectedItem: {},
    };
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    editItemLine: function (item) {
      this.$emit("edit-item-list-line", item);
    },
    editItemMenuChoices: function (item) {
      this.displayMenuDialog = true;
      this.selectedItem = item;
    },
    closeMenuDialog: function () {
      this.displayMenuDialog = false;
    },
  },
  computed: {
    lineTotalPrice: function () {
      let number = null;
      if (this.itemClone.discount.type === "percentage") {
        number =
          this.itemClone.item_unit_price * this.itemClone.quantity * (1 - (this.itemClone.discount.value || 0) / 100);
      } else {
        number = this.itemClone.item_unit_price * this.itemClone.quantity - (this.itemClone.discount.value || 0);
      }

      if (number !== undefined) {
        return displayWithCents(round2Decimals(number));
      }

      return null;
    },
    quantityRules: function () {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) => (v && v >= 0) || this.$vuetify.lang.t("$vuetify.valueAbove0"),
      ];
    },
    discountRules: function () {
      var errors = [];

      errors.push(
        (v) => v !== undefined || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) =>
          this.itemClone.discount.type !== "percentage" ||
          (v && v <= 100 && v >= 0) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0to100"),
        (v) =>
          this.itemClone.discount.type !== "value" ||
          (v && v <= this.itemClone.quantity * this.itemClone.item_unit_price && v >= 0) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0toX") + this.itemClone.quantity * this.itemClone.item_unit_price
      );

      return errors;
    },
    unitPriceRules: function () {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) => (v && v >= 0) || this.$vuetify.lang.t("$vuetify.valueAbove0"),
      ];
    },
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
  },
  created() {
    //@modified ana.castro 2024.02.05 SAFO-85
    //Once an item has the "extra_info" filled in, its quantity can't be modified. It should always be equal to 1.
    if (this.itemClone.item_extra_info) {
      this.isQuantityDisabled = true;
    }

    this.itemClone.discount.value = this.itemClone.discount.value || "0";

    if (!this.itemClone.discount.type) {
      this.itemClone.discount.type = "value";
    }
    //@modified ana.castro 2021.07.05 SABO-2084
    this.$store.dispatch("items/getLookUpSearchTerms", this.itemClone.item_id).then((result) => {
      let itemSearchFor = null;
      let editItemInfo = null;

      if (result[0].parentItem) {
        itemSearchFor = result[0].parentItem;
      } else {
        itemSearchFor = result[0].id;
      }

      this.$store.dispatch("items/get", itemSearchFor).then((result) => {
        editItemInfo = result[0];
        if (editItemInfo) {
          if (editItemInfo["data"]["variableSalePrice"] && editItemInfo["data"]["variableSalePrice"] === 1) {
            this.isPriceDisabled = false;
          }
        }
      });
    });

    this.ready = true;
  },
  mounted() {
    this.discountTypes = [
      { value: "value", text: "€ " + this.$vuetify.lang.t("$vuetify.valuePay") },
      { value: "percentage", text: "% (" + this.$vuetify.lang.t("$vuetify.percentage") + ")" },
    ];
  },
};
</script>

<style scoped></style>
