<template>
  <v-layout row wrap class="catalog-items-panel pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15">
    <v-flex xs12>
      <v-card class="mx-auto checkout-customer-panel">
        <v-card-title>
          <v-flex xs12>
            <v-row xs12> Cliente</v-row>
            <v-row xs12 class="text-sm-body-2">
              {{ $vuetify.lang.t("$vuetify.msgDetailsInvoice") }}
            </v-row>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-spacer class="my-3"></v-spacer>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="customerName"
                class="input-simple-keyboard"
                @click="
                  isKeyboardOpen = true;
                  isKeyboardOpenEmail = false;
                  isKeyboardOpenNIF = false;
                "
                label="Nome"
              ></v-text-field>
              <SimpleKeyboard
                v-if="isKeyboardOpen"
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="customerName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="customerEmail"
                class="input-simple-keyboard"
                @click="
                  isKeyboardOpenEmail = true;
                  isKeyboardOpen = false;
                  isKeyboardOpenNIF = false;
                "
                label="E-mail"
              ></v-text-field>
              <SimpleKeyboard
                v-if="isKeyboardOpenEmail"
                @onChange="onChange2"
                @onKeyPress="onKeyPress2"
                :input="customerEmail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="taxNumber"
                class="input-simple-keyboard"
                label="NIF"
                @click="
                  isKeyboardOpenEmail = false;
                  isKeyboardOpen = false;
                  isKeyboardOpenNIF = true;
                "
                :rules="taxNumberRules"
              ></v-text-field>

              <SimpleKeyboardNumeric
                v-if="isKeyboardOpenNIF"
                @onChange="onChange3"
                @onKeyPress="onKeyPress3"
                :input="taxNumber"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row class="ma-2" x-large justify="center" align="center"></v-row>
      <v-spacer />

      <v-btn block @click="nextPayments" class="light-green" x-large>
        <small>{{ $vuetify.lang.t("$vuetify.payment") }}</small>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { validateTaxNumberPT } from "@/_helpers";
import SimpleKeyboard from "@/components/SimpleKeyboard";
import SimpleKeyboardNumeric from "@/components/SimpleKeyboardNumeric";

export default {
  name: "CheckoutClient",
  data: () => ({
    taxNumber: undefined,
    customerName: null,
    customerEmail: null,
    isKeyboardOpen: false,
    isKeyboardOpenEmail: false,
    isKeyboardOpenNIF: false,
  }),
  components: {
    SimpleKeyboard,
    SimpleKeyboardNumeric,
  },
  methods: {
    nextPayments() {
      this.$router.push({ name: "shop-cart-selfcheckout-details-payment" }).catch(() => {
        /* NavigationDuplicated if the "Pay" button is clicked again */
      });
      this.$store.dispatch("cart/setCheckoutCartStatus", "inProgress");
    },
    onChange(input) {
      this.customerName = input;
    },
    onKeyPress(button) {
      if (button === "{enter}") {
        this.isKeyboardOpen = false;
        this.isKeyboardOpenEmail = false;
        this.isKeyboardOpenNIF = false;
      }
    },
    onInputChange(input) {
      this.customerName = input.target.value;
    },
    onChange2(input) {
      this.customerEmail = input;
    },
    onKeyPress2(button) {
      if (button === "{enter}") {
        this.isKeyboardOpen = false;
        this.isKeyboardOpenEmail = false;
        this.isKeyboardOpenNIF = false;
      }
    },
    onInputChange2(input) {
      this.customerEmail = input.target.value;
    },
    onChange3(input) {
      this.taxNumber = input;
    },
    onKeyPress3(button) {
      if (button === "{enter}") {
        this.isKeyboardOpen = false;
        this.isKeyboardOpenEmail = false;
        this.isKeyboardOpenNIF = false;
      }
    },
    onInputChange3(input) {
      this.taxNumber = input.target.value;
    },
  },
  computed: {
    taxNumberRules: function () {
      let errors = [];

      //Only digits: has to be length 9 and validateTaxNumberPT
      //Starts with PT/pt:  has to be length 11 and validateTaxNumberPT
      //Another formats: no validations are applied
      errors.push((v) => {
        if (!v) {
          return true;
        } else if (
          ((/^\d+$/.test(v) && v.length === 9) || (/^PT\d+$/i.test(v) && v.length === 11)) &&
          !validateTaxNumberPT(v.replace("PT", "").replace("pt", ""))
        ) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        } else if ((/^\d+$/.test(v) && v.length !== 9) || (/^PT\d+$/i.test(v) && v.length !== 11)) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN9");
        } else if (v.length < 3) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        }

        return true;
      });

      return errors;
    },
  },
};
</script>

<style scoped>
.simple-keyboard {
  position: absolute;
  z-index: 25;
  width: 700px;
}

.numeric-simple-keyboard {
  position: absolute;
  z-index: 25;
  width: 700px;
}
.numeric-simple-keyboard .hg-row {
  height: 70px !important;
}

.numeric-theme {
  max-width: 320px;
  margin: 0 auto;
}

.numeric-theme + .simple-keyboard-preview {
  max-width: 320px;
}

.simple-keyboard.hg-theme-default.numeric-theme .hg-button.hg-standardBtn.hg-button-at {
  max-width: none;
}
</style>
