<template>
  <v-container class="px-0" fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $vuetify.lang.t("$vuetify.admin.deviceConfiguration") }}</v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-checkbox v-model="devicePrinter" :label="$vuetify.lang.t('$vuetify.admin.printer')"></v-checkbox>
                  <!--<v-checkbox v-model="devicePrinter" label=["$vuetify.admin.printer"]></v-checkbox>-->
                  <!--  <v-checkbox v-model="devicePrinter" label={{$vuetify('experim')}}></v-checkbox>-->
                  <v-checkbox v-model="deviceDrawer" :label="$vuetify.lang.t('$vuetify.admin.cashDrawer')"></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-model="deviceBalance" :label="$vuetify.lang.t('$vuetify.admin.scale')"></v-checkbox>
                  <v-checkbox
                    v-model="deviceVirtualKeyboard"
                    :label="$vuetify.lang.t('$vuetify.admin.virtualKeyboard')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $vuetify.lang.t("$vuetify.admin.generalSettings") }}</v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-select
                    v-model="itemsDescription"
                    :items="itemsDescriptionsChoices"
                    item-text="name"
                    item-value="value"
                    :label="$vuetify.lang.t('$vuetify.admin.productDescription')"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="6">
                  <v-checkbox
                    v-model="selectSalesman"
                    :label="$vuetify.lang.t('$vuetify.admin.employeeSelection')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-select
                    v-model="printPageSize"
                    :items="printPageSizeChoices"
                    item-text="name"
                    item-value="value"
                    :label="$vuetify.lang.t('$vuetify.admin.sizePrintDoc')"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" sm="6">
                  <v-checkbox
                    v-model="useLocalTime"
                    :label="$vuetify.lang.t('$vuetify.admin.useLocalTime')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $vuetify.lang.t("$vuetify.admin.dataSynchronization") }}</v-card-title>
          <v-card-subtitle> {{ $vuetify.lang.t("$vuetify.admin.updateMessage") }}</v-card-subtitle>
          <v-card-text>
            <v-checkbox
              v-for="(item, i) in options"
              :key="i"
              v-model="selected"
              :disabled="!isChecksEnabled"
              :label="item.title"
              :value="item.value"
            ></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!isSyncButtonEnabled" class="primary" large @click="submit">
              {{ $vuetify.lang.t("$vuetify.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {
//   paymentTypesService,
//   familiesService,
//   itemsService,
//   salesmansService,
//   cashDrawersService,
// } from "@/services";

export default {
  name: "Administration",
  data: () => ({
    selected: [],
    runningTask: false,
    options: [],
    printPageSizeChoices: [],
    itemsDescriptionsChoices: [],
  }),

  methods: {
    defineOptions() {
      this.options = [
        {
          title: this.$vuetify.lang.t("$vuetify.paymentMethods"),
          value: 1,
          commit: "paymentTypes/setConfiguration",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.admin.families"),
          value: 2,
          commit: "families/setConfiguration",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.admin.product"),
          value: 3,
          commit: "items/setConfiguration",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.admin.cashier"),
          value: 5,
          commit: "salesmen/setConfiguration",
        },

        {
          title: this.$vuetify.lang.t("$vuetify.admin.cashRegister"),
          value: 6,
          commit: "cashDrawers/setConfiguration",
        },
      ];
      this.printPageSizeChoices = [
        { name: this.$vuetify.lang.t("$vuetify.admin.receiptRoll"), value: "1" },
        { name: this.$vuetify.lang.t("$vuetify.admin.a4"), value: "2" },
      ];
      this.itemsDescriptionsChoices = [
        { name: this.$vuetify.lang.t("$vuetify.admin.short"), value: "1" },
        { name: this.$vuetify.lang.t("$vuetify.admin.complete"), value: "2" },
      ];
    },
    submit() {
      this.runningTask = true;
      let promises = [];

      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      for (const key in this.selected) {
        let selectedOption = this.options.find((option) => option.value === this.selected[key]);
        if (!selectedOption.commit) {
          continue;
        }

        let promise = new Promise((resolve, reject) => {
          let autoSync = 0;
          let forceUpdate = 1;
          this.$store.dispatch(selectedOption.commit, { forceUpdate, autoSync }).then(
            // eslint-disable-next-line
            (result) => {
              resolve();
            },
            // eslint-disable-next-line
            (error) => {
              reject(this.$vuetify.lang.t("$vuetify.infoNotPossible") + selectedOption.title + ".");
            }
          );
        });
        promises.push(promise);
      }

      Promise.allSettled(promises).then((results) => {
        this.runningTask = false;
        this.$store.dispatch("loader/setLoading", false);

        let errorsExist = false;
        let errorMessage = "";
        for (var i = 0; i < results.length; i++) {
          if (results[i].status === "rejected") {
            errorsExist = true;
            errorMessage += " " + results[i].reason;
          }
        }

        if (errorsExist) {
          this.$store.dispatch("alert/error", errorMessage);
        } else {
          this.$store.dispatch("alert/success", this.$vuetify.lang.t("$vuetify.admin.syncMessageSuccess"));
        }
      });
    },
    getDeviceProps(device, prop) {
      let deviceProps = JSON.parse(localStorage.getItem("deviceProps"));

      if (deviceProps !== null && device in deviceProps) {
        if (deviceProps[device][prop] !== null && prop in deviceProps[device]) {
          return deviceProps[device][prop];
        } else return 0;
      } else {
        return 0;
      }
    },
    setDeviceProps(device, prop, value) {
      let deviceProps = JSON.parse(localStorage.getItem("deviceProps"));

      if (deviceProps === null) {
        deviceProps = {};
      }
      if (deviceProps[device] === undefined) {
        deviceProps[device] = {};
      }
      deviceProps[device][prop] = value;

      localStorage.setItem("deviceProps", JSON.stringify(deviceProps));
    },
  },
  computed: {
    isSyncButtonEnabled() {
      return this.selected.length > 0 && !this.runningTask;
    },
    isChecksEnabled() {
      return !this.runningTask;
    },
    devicePrinter: {
      get: function () {
        return this.getDeviceProps("printer", "status");
      },
      set: function (newValue) {
        this.setDeviceProps("printer", "status", newValue);
      },
    },
    deviceDrawer: {
      get: function () {
        return this.getDeviceProps("drawer", "status");
      },
      set: function (newValue) {
        this.setDeviceProps("drawer", "status", newValue);
      },
    },
    deviceBalance: {
      get: function () {
        return this.getDeviceProps("balance", "status");
      },
      set: function (newValue) {
        this.setDeviceProps("balance", "status", newValue);
      },
    },
    deviceVirtualKeyboard: {
      get: function () {
        return this.getDeviceProps("virtualKeyboard", "status");
      },
      set: function (newValue) {
        this.setDeviceProps("virtualKeyboard", "status", newValue);
      },
    },
    printPageSize: {
      get: function () {
        return this.$store.getters["configs/printPageSize"];
      },
      set: function (newValue) {
        this.$store.dispatch("configs/setPrintPageSize", newValue.value);
      },
    },
    itemsDescription: {
      get: function () {
        return this.$store.getters["configs/itemsDescription"];
      },
      set: function (newValue) {
        this.$store.dispatch("configs/setItemsDescription", newValue.value);
      },
    },
    selectSalesman: {
      get: function () {
        return this.$store.getters["configs/selectSalesman"];
      },
      set: function (newValue) {
        this.$store.dispatch("configs/setSelectSalesman", newValue);
      },
    },
    useLocalTime: {
      get: function () {
        return this.$store.getters["configs/useLocalTime"];
      },
      set: function (newValue) {
        this.$store.dispatch("configs/setUseLocalTime", newValue);
      },
    },
  },
  mounted() {
    this.defineOptions();
  },
};
</script>

<style scoped></style>
