<!-- This Vue is used to inform the user that his password is expired

In case an API request receives a 302 response to /passwordchange/ we
redirect the user to this page
-->
<template>
  <v-app id="expired-password">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-title>{{ $vuetify.lang.t("$vuetify.expiredPassword") }}</v-card-title>
              <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.expiredPasswordMessage") }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="openPasswordChange">{{ $vuetify.lang.t("$vuetify.updatePassword") }}</v-btn>
                <v-btn class="primary" text @click="goBackToLogin">{{
                  $vuetify.lang.t("$vuetify.goBackToLogin")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getApiBaseUrl } from "@/_helpers";

export default {
  name: "ExpiredPassword",
  data: () => ({}),
  methods: {
    goBackToLogin() {
      this.$router.push({
        name: "login",
      });
    },
    openPasswordChange() {
      window.open(getApiBaseUrl() + "user/passwordchange/", "_blank");
    },
  },
};
</script>

<style></style>
