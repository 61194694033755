<!-- This Vue is used to Open/Close Salesman Sessions.
It displays three elements:
1 - the Salesman assigned to the Store
2 - when the session was opened (if it is open)
3 - two buttons to enable opening/closing sessions

The Salesman Sessions information is stored on the "salesmen" table of the IndexedDB. There are two attributes linked
to this:
(1) - sessionStatus: accepts the values 0 (close) or 1 (open)
and
(2) - sessionDate: when the session was opened or closed

To perform the action of opening/closing a session the API "pos/salesmansessions/ is being used.

After opening a session, the Salesman flag "sessionStatus" is set to true. Through all the application, it would be
possible to select the Salesman to set it as the "active" Salesman.
-->
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card
        class="pa-1 my-2"
        :color="salesman.sessionStatus === 1 ? 'grey lighten-4' : ''"
        v-for="(salesman, i) in salesmen"
        :key="i"
      >
        <v-card-title>{{ salesman.name }}</v-card-title>

        <v-card-text v-if="salesman.sessionStatus === 1">
          <div v-if="salesman.sessionDate" class="text--primary">{{ salesman.sessionDate }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="salesman.sessionStatus === 0 || !salesman.sessionStatus"
            @click="openSalesmanSession(salesman)"
            color="blue"
            text
          >
            {{ $vuetify.lang.t("$vuetify.salesmenSessionOpen") }}
          </v-btn>
          <v-btn v-if="salesman.sessionStatus === 1" @click="closeSalesmanSession(salesman)" color="red" text>
            {{ $vuetify.lang.t("$vuetify.salesmenSessionClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-dialog v-model="displayDialogSalesmanPassword" max-width="300px">
      <v-card>
        <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.password") }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-if="displayDialogSalesmanPassword"
            autofocus
            v-model="salesmenPassword"
            :append-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
            ref="salesmanPassword"
            :type="showPassword ? 'text' : 'password'"
            outlined
            dense
            @click:append="showPassword = !showPassword"
            @keydown.enter="validateSalesmanAndPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialogSalesmanPassword()"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
          <v-btn class="primary" text @click="validateSalesmanAndPassword()">{{
            $vuetify.lang.t("$vuetify.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { salesmenService } from "@/services";
import { getErrorMessage } from "@/_helpers";
import bcrypt from "bcryptjs";
export default {
  name: "SalesmenSessions",
  data: () => ({
    salesmen: [],
    displayDialogSalesmanPassword: false,
    showPassword: false,
    salesmenPassword: null,
    actionType: null,
    selectedSalesman: null,
  }),
  methods: {
    getAllSalesmen() {
      this.$store.dispatch("salesmen/getAll").then((result) => {
        this.salesmen = result;
      });
    },
    openSalesmanSession: function (salesman) {
      this.$store.dispatch("salesmen/get", salesman.id).then(
        (result) => {
          if (result && result[0] && result[0]["data"]["SalesmanPasswordE"]) {
            this.displayDialogSalesmanPassword = true;
            this.selectedSalesman = salesman;
            this.actionType = 1;
          } else {
            this.submitSalesmanSession(salesman, 1);
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorInfoEmployee") + this.salesman, this);
        }
      );
    },
    closeSalesmanSession(salesman) {
      this.$store.dispatch("salesmen/get", salesman.id).then(
        (result) => {
          if (result && result[0] && result[0]["data"]["SalesmanPasswordE"]) {
            this.displayDialogSalesmanPassword = true;
            this.selectedSalesman = salesman;
            this.actionType = 0;
          } else {
            this.submitSalesmanSession(salesman, 0);
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorInfoEmployee") + this.salesman, this);
        }
      );
    },
    submitSalesmanSession: function (salesman, actionType) {
      // 1 - setting the Loading Layer
      this.$store.dispatch("loader/setLoading", true);

      // 2 - an object is defined with all the information which will be passed to the backend
      let currentdate = new Date();
      let month = "" + (currentdate.getMonth() + 1);
      let day = "" + currentdate.getDate();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      let datetime =
        currentdate.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();
      let obj = {
        salesman: salesman.id,
        startTime: actionType === 1 ? datetime : null,
        endTime: actionType === 0 ? datetime : null,
      };

      salesmenService.setSalesmenSessions(obj).then(
        // eslint-disable-next-line
        (result) => {
          //Receiving a successful response:
          // 1 - the Salesman register on the IndexedDB is updated
          // 2 - the variable this.salesmen is updated to refresh the information displayed on the interface

          let salesmanId = salesman.id;
          var salesmanIndex = this.salesmen.findIndex((x) => x.id === salesman.id);
          var salesmanToUpdate = this.salesmen[salesmanIndex];

          let date = datetime;
          if (actionType === 1) {
            salesmanToUpdate.sessionStatus = 1;
            salesmanToUpdate.sessionDate = date;

            this.$store.dispatch("salesmen/setSalesmanSessionOpen", { salesmanId, date });
          } else {
            salesmanToUpdate.sessionStatus = 0;
            salesmanToUpdate.sessionDate = date;

            this.$store.dispatch("salesmen/setSalesmanSessionClose", { salesmanId, date });
          }

          this.salesmen[salesmanIndex] = salesmanToUpdate;

          this.$store.dispatch("loader/setLoading", false);

          if (actionType === 1) {
            this.$store.dispatch("alert/success", this.$vuetify.lang.t("$vuetify.msgOpenSessionSuccess"));
          } else {
            this.$store.dispatch("alert/success", this.$vuetify.lang.t("$vuetify.msgCloseSessionSuccess"));
          }

          this.actionType = null;
          this.selectedSalesman = null;
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            this.$vuetify.lang.t("$vuetify.msgContentNotAvailable") +
              " " +
              this.$vuetify.lang.t("$vuetify.errorRegisterSessionMovement")
          );
          getErrorMessage(error, "Set Pos Salesmen Session", this);

          this.actionType = null;
          this.selectedSalesman = null;
        }
      );
    },
    getSalesmenMasterPassword() {
      let currentDate = new Date();
      let day = "" + currentDate.getDate();
      let minutes = "" + currentDate.getMinutes();
      if (day.length < 2) day = "0" + day;
      if (minutes.length < 2) minutes = "0" + minutes;
      return currentDate.getFullYear() + day + minutes;
    },
    closeDialogSalesmanPassword() {
      this.displayDialogSalesmanPassword = false;
      this.salesmenPassword = null;
    },
    validateSalesmanAndPassword: function () {
      let salesmenMasterPassword = this.getSalesmenMasterPassword();
      let password_valid = false;

      this.$store.dispatch("salesmen/get", this.selectedSalesman.id).then(
        (result) => {
          if (result && result[0]) {
            if (this.salesmenPassword === salesmenMasterPassword) {
              password_valid = true;
            } else {
              if (
                result[0]["data"]["SalesmanPasswordE"] &&
                this.salesmenPassword &&
                bcrypt.compareSync(this.salesmenPassword, result[0]["data"]["SalesmanPasswordE"])
              ) {
                password_valid = true;
              } else if (!result[0]["data"]["SalesmanPasswordE"] && !this.salesmenPassword) {
                password_valid = true;
              } else {
                password_valid = false;
              }
            }
            if (password_valid) {
              this.displayDialogSalesmanPassword = false;
              this.salesmenPassword = null;
              this.submitSalesmanSession(this.selectedSalesman, this.actionType);
            } else {
              this.displayDialogSalesmanPassword = false;
              this.$store.dispatch(
                "alert/error",
                this.$vuetify.lang.t("$vuetify.errorValidatingCredentials") +
                  " " +
                  this.$vuetify.lang.t("$vuetify.tryAgain")
              );
            }
            this.salesmenPassword = null;
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorInfoEmployee") + this.selectedSalesman, this);
        }
      );
    },
  },
  mounted() {
    this.getAllSalesmen();
  },
};
</script>

<style scoped></style>
