<template>
  <v-layout row wrap class="pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15">
    <v-flex xs12 justify-center>
      <v-card>
        <v-card-text>
          <h1 style="text-align: center; padding-bottom: 50px">{{ $vuetify.lang.t("$vuetify.welcome") }}</h1>
        </v-card-text>

        <div class="image-container">
          <img
            :src="require('@/assets/tiger_logo.jpg')"
            alt="Tiger"
            style="text-align: center"
            width="auto"
            height="250"
          />
        </div>

        <v-card-actions class="justify-center">
          <v-btn @click="start" class="primary" style="height: 200px; width: 350px" x-large>
            {{ $vuetify.lang.t("$vuetify.startPurchase") }}
          </v-btn>
        </v-card-actions>
        <br /><br /><br /><br /><br /><br />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "CheckoutHome",
  methods: {
    start() {
      this.$store.dispatch("cart/setCartEmpty");
      this.$router.push({ name: "catalog" }).catch(() => {
        /* NavigationDuplicated if the "Pay" button is clicked again */
      });
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
}
</style>
