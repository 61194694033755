<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="report in reports" :key="report.title">
        <v-card hover @click="getReport(report)">
          <v-card-title v-text="report.title"></v-card-title>

          <v-card-subtitle v-text="report.subTitle"></v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="displayDialogOneDate" persistent max-width="400px">
      <v-card>
        <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.selectDate") }}</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text style="overflow: auto">
          <v-flex xs12>
            <v-row>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="searchDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchDate"
                      :label="$vuetify.lang.t('$vuetify.date')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="searchDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(searchDate)"> OK </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-flex>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="displayDialogOneDate = false"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
            <v-btn large class="primary" @click="submit()" :disabled="!searchDate">
              {{ $vuetify.lang.t("$vuetify.submit") }}</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displayDocument" persistent max-width="400px">
      <DisplayPDF v-if="displayDocument" :source="documentSource" @close-pdf="closeDocument"></DisplayPDF>
    </v-dialog>
  </v-container>
</template>

<script>
import DisplayPDF from "@/components/DisplayPDF.vue";
import { getApiBaseStore, getApiBaseUrl } from "../_helpers";

export default {
  name: "Reports",
  components: { DisplayPDF },

  data: () => ({
    reports: [],
    selectedReport: null,
    displayDialogOneDate: false,
    displayDocument: false,
    documentSource: null,
    menu: false,
    searchDate: null,
  }),
  methods: {
    defineReports() {
      this.reports = [
        {
          title: this.$vuetify.lang.t("$vuetify.sales"),
          subTitle: this.$vuetify.lang.t("$vuetify.salesSub"),
          info: "oneDate",
          reportName: "dailysales",
        },
      ];
    },
    getReport(report) {
      this.selectedReport = report;
      if (report.info) {
        if (report.info === "oneDate") {
          this.displayDialogOneDate = true;
        }
      }
    },
    closeDocument() {
      this.displayDialogOneDate = false;
      this.displayDocument = false;
      this.documentSource = null;
      this.searchDate = null;
    },
    submit() {
      let str = "start_date=" + this.searchDate + "&end_date=" + this.searchDate;
      let fetchUrl =
        getApiBaseUrl() +
        "dimas/api/pos/reports/" +
        this.selectedReport.reportName +
        "/?store=" +
        getApiBaseStore() +
        "&" +
        str;

      this.displayDocument = true;
      this.documentSource = fetchUrl;
    },
  },
  mounted() {
    this.defineReports();
  },
};
</script>

<style scoped></style>
