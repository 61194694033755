<template>
  <v-card>
    <v-card-title class="title">{{ title }}</v-card-title>
    <v-spacer></v-spacer>
    <v-card-subtitle v-if="confirmMessage">{{ confirmMessage }}</v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="displayCancelButton" text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
      <v-btn class="primary" text @click="confirmAction">{{ $vuetify.lang.t("$vuetify.ok") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmAction",
  props: {
    title: String,
    confirmMessage: String,
    displayCancelButton: Boolean,
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    confirmAction: function () {
      this.$emit("confirm-action");
    },
  },
};
</script>

<style scoped></style>
