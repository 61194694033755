<template>
  <v-container>
    <v-card-text style="height: 50vh; overflow: auto">
      <v-flex xs12>
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="searchCloseTenderDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="searchCloseTenderDate"
                  :label="$vuetify.lang.t('$vuetify.date')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="searchCloseTenderDate"
                :disabled="isSearching"
                no-title
                scrollable
                @input="handleDateSelect"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-icon medium right @click="applyCloseTenderSearchByDate">fal fa-search</v-icon>
          </v-col>
        </v-row>
      </v-flex>
      <div class="text--primary" v-if="searchCloseTenderDate && !cashDrawerMovementsDocuments.length && !isSearching">
        {{ $vuetify.lang.t("$vuetify.noResults") }}
      </div>
      <v-progress-circular v-if="isSearching" color="primary" indeterminate></v-progress-circular>
      <v-card class="pa-1 my-2" v-for="(document, i) in cashDrawerMovementsDocuments" :key="i">
        <v-card-title>{{ document.tenderTransactionNo }}</v-card-title>

        <v-card-text>
          <div class="text--primary">
            {{ $vuetify.lang.t("$vuetify.total") }} {{ document.totalAmount }} {{ currencySymbol }}
          </div>
          <div class="text--primary">
            {{ document.dateHour }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="printDocument(document)" color="blue" text> {{ $vuetify.lang.t("$vuetify.print") }}</v-btn>
          <v-spacer></v-spacer>

          <v-btn icon @click="changeShow(i)">
            <v-icon small>{{ show[i] ? "fas fa-arrow-up" : "fas fa-arrow-down" }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show[i]">
            <v-divider></v-divider>

            <v-card-text>
              <v-list class="cart-items-list">
                <v-list-item v-for="(detail, i) in document.details" :key="i">
                  <v-list-item-title> {{ detail.Amount }} {{ currencySymbol }}</v-list-item-title>
                  <v-list-item-subtitle v-text="detail.TenderDescription"></v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="document.pattyCash">
                  <v-list-item-title> {{ document.pattyCash }} {{ currencySymbol }}</v-list-item-title>
                  <v-list-item-subtitle :v-text="$vuetify.lang.t('$vuetify.pattyCash')"></v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
      </v-card-actions>
    </v-card-text>

    <v-dialog v-model="displayDocument" persistent max-width="400px">
      <DisplayPDF v-if="displayDocument" :source="documentSource" @close-pdf="closeDocument"></DisplayPDF>
    </v-dialog>
  </v-container>
</template>

<script>
import { cashDrawersService } from "@/services";
import { mapGetters } from "vuex";
import DisplayPDF from "@/components/DisplayPDF.vue";
import { getApiBaseUrl, getErrorMessage } from "@/_helpers";

export default {
  name: "CashDrawersSearch",
  props: {
    cashDrawerCode: String,
  },
  components: { DisplayPDF },
  data: () => ({
    cashDrawerMovementsDocuments: null,
    show: [],
    searchCloseTenderDate: null,
    menu: false,
    modal: false,
    loading: false,
    hasMoreResults: true,
    nextPageUrl: null,
    displayDocument: false,
    documentSource: null,
    isSearching: false,
  }),
  methods: {
    handleDateSelect(date) {
      this.menu = false;
      this.$refs.menu.save(date);
    },
    changeShow(index) {
      this.show[index] = !this.show[index];
      this.$forceUpdate();
    },
    applyCloseTenderSearchByDate() {
      if (this.isSearching || !this.searchCloseTenderDate) return;

      this.isSearching = true;
      this.cashDrawerMovementsDocuments = [];
      let params = {
        date: this.searchCloseTenderDate,
        type: 0,
        cashdrawer: this.cashDrawerCode,
      };

      cashDrawersService.getAllCashDrawerCloseMovements(params).then(
        (result) => {
          for (var i = 0; i < result.length; i++) {
            this.cashDrawerMovementsDocuments.push({
              tenderTransactionNo: result[i].TenderTransactionNo,
              totalAmount: result[i].TotalAmount,
              dateHour: result[i].Createstamp.replace("T", " ").slice(0, 19),
              pattyCash: result[i].PattyCash,
              details: result[i].TenderTransactionDetails,
              printRollUrl: result[i].PrintRollUrl,
            });
          }
          this.isSearching = false;
          this.$store.dispatch("loader/setLoading", false);
        },
        (error) => {
          this.isSearching = false;
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.cashDrawersSearchPage.gettingResults"), this);
        }
      );
    },
    printDocument(document) {
      this.displayDocument = true;
      this.documentSource = getApiBaseUrl() + document.printRollUrl.substring(1);
    },
    closeDocument: function () {
      this.displayDocument = false;
      this.documentSource = null;
    },
    cancelPrintDocument: function () {
      this.displayDocument = false;
      this.documentSource = null;
    },
    closeDialog: function () {
      this.cashDrawerMovementsDocuments = [];
      this.searchCloseTenderDate = null;
      this.$emit("close-dialog");
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
  },
};
</script>

<style scoped></style>
