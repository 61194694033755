<template>
  <v-flex>
    <v-list two-line class="cart-items-list">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        class="v-list-item--link"
        @click="enableDialogEdition ? displayEditDialog(item) : null"
      >
        <v-list-item-avatar class="cart-item-quantity-input">
          <v-text-field
            v-model="item.quantity"
            mask="###"
            :disabled="!enableEditions || item.item_extra_info !== undefined"
            @change="editItemLineQtd(item)"
            @click.stop
          ></v-text-field>
        </v-list-item-avatar>
        <!--            :rules="quantityRules"-->

        <v-list-item-content>
          <v-list-item-title class="cart-item-name"
            >{{ item.item_name }}
            <span v-if="item.item_extra_info"> [{{ item.item_extra_info }}] </span></v-list-item-title
          >

          <v-list-item-subtitle>
            <span v-if="item.item_code">{{ item.item_code }} </span>
            <!-- To display the Discount Percentage Value -->
            <span
              v-if="item.discount.value > 0 && item.discount.type === 'percentage' && item.discount_loyalty.value === 0"
              v-show="$vuetify.breakpoint.mdAndUp"
              class="discount-percent"
              >-{{ parseFloat(item.discount.value).toFixed(0) }}%
            </span>
            <!-- To display the Discount Amount Value -->
            <span
              v-if="
                item.discount.type === 'value' ||
                (item.discount.value === 0 && item.discount_value_total > 0) ||
                (item.discount.type === 'percentage' &&
                  item.discount_loyalty.value > 0 &&
                  item.discount_value_total > 0)
              "
              class="discount-percent"
              v-show="$vuetify.breakpoint.mdAndUp"
              >-{{ parseFloat(item.discount_value_total).toFixed(2) + currencySymbol }}
            </span>
          </v-list-item-subtitle>
          <!-- Below the Item, if it is a Menu Item, it is necessary to list the Menu component's: description and price -->
          <template v-for="menuLevels in item.menu_items">
            <v-list-item-subtitle v-for="(menuLevelsItems, k) in menuLevels.items" :key="k">
              {{ menuLevelsItems.description }}
            </v-list-item-subtitle>
          </template>
          <!-- Below the Item, it is possible to have extra or information Items. 
          For these Items, it is necessary to list their description and price -->
          <template>
            <v-list-item-subtitle v-for="(components, l) in item.components" :key="l">
              {{ components.description }}
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-subtitle v-if="item.discount_value_total > 0 && item.item_unit_price" class="discounted-price">
            {{ (item.item_unit_price * item.quantity).toFixed(2) + currencySymbol }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else class="discounted-price"> </v-list-item-subtitle>

          <v-list-item-title>
            {{ lineTotalPrice(item) + currencySymbol }}
          </v-list-item-title>

          <!-- Below the Item, if it is a Menu Item, it is necessary to list the Menu component's: description and price -->
          <template v-for="menuLevels in item.menu_items">
            <v-list-item-subtitle v-for="(menuLevelsItems, l) in menuLevels.items" :key="l">
              {{ (menuLevelsItems.item_unit_price * item.quantity).toFixed(2) + currencySymbol }}
            </v-list-item-subtitle>
          </template>
          <!-- Below the Item, it is possible to have extra or information Items. 
          For these Items, it is necessary to list their description and price -->
          <template>
            <v-list-item-subtitle v-for="(component, m) in item.components" :key="m">
              {{ component.item_unit_price.toFixed(2) + currencySymbol }}
            </v-list-item-subtitle>
          </template>
        </v-list-item-action>

        <v-list-item-action>
          <v-icon
            v-if="enableEditions"
            @click="removeItem(item.item_id, item.order)"
            @click.stop
            right
            small
            color="error"
            class="cart-item-del"
            >far fa-trash
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-dialog v-if="enableDialogEdition" v-model="displayEditLineDialog" max-width="600px">
      <ItemsListLineEdit
        v-if="displayEditLineDialog"
        :item="selectedItem"
        @close-dialog="closeDialog"
        @edit-item-list-line="editItemListLine"
      ></ItemsListLineEdit>
    </v-dialog>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import { round2Decimals, displayWithCents } from "@/_helpers";
import ItemsListLineEdit from "@/components/ItemsListLineEdit.vue";

export default {
  name: "ItemsList",
  components: { ItemsListLineEdit },
  //Parent (....) to Child (ItemsList.vue) Communication
  //The "props" are the elements which enable moving data from a parent component to a child component
  //"Props" is short for “properties” and is referring to properties set from the parent component
  //Note that props are strictly for one way communication from the parent to the child,
  // and you do not want to try and change the value of a prop directly in the child component.
  // To change a parent's property, a computed property from the parent should be used.
  props: {
    items: Array,
    enableEditions: Boolean,
    enableDialogEdition: Boolean,
    enableChangeQuantityPlus: Boolean,
  },
  data: () => ({
    itemsClone: null,
    displayEditLineDialog: false,
    selectedItem: {},
  }),
  methods: {
    /**
     * The Item is excluded from the Shopping Cart
     * @param itemId
     * @param itemOrder
     */
    removeItem(itemId, itemOrder) {
      this.$store.dispatch("cart/deleteFromCart", { itemId, itemOrder });
    },
    displayEditDialog: function (item) {
      this.displayEditLineDialog = true;
      this.selectedItem = item;
    },
    closeDialog: function () {
      this.displayEditLineDialog = false;
    },
    //daniel.silva When we change the qty we need to calculate the applied line discount
    // TODO verificar desconto de fidelização e outras implicações
    // é precido rever tb o lineTotalPrice??
    editItemLineQtd: function (editedItem) {
      let itemId = editedItem["item_id"];
      let itemCode = editedItem["item_code"];
      let itemUnitPrice = parseFloat(editedItem["item_unit_price"]);
      let itemDiscount = parseFloat(editedItem["discount"].value);
      let itemDiscountType = editedItem["discount"].value > 0 ? editedItem["discount"].type : null;
      let quantity = parseInt(editedItem["quantity"]);
      let itemOrder = parseInt(editedItem["order"]);

      this.$store.commit("cart/editElementInCart", {
        itemId,
        itemCode,
        itemUnitPrice,
        itemDiscount,
        itemDiscountType,
        quantity,
        itemOrder,
      });
    },
    editItemListLine: function (editedItem) {
      let itemId = editedItem["item_id"];
      let itemCode = editedItem["item_code"];
      let itemUnitPrice = parseFloat(editedItem["item_unit_price"]);
      let itemDiscount = parseFloat(editedItem["discount"].value);
      let itemDiscountType = editedItem["discount"].value > 0 ? editedItem["discount"].type : null;
      let quantity = parseInt(editedItem["quantity"]);
      let itemOrder = parseInt(editedItem["order"]);

      this.$store.dispatch("cart/editElementInCart", {
        itemId,
        itemCode,
        itemUnitPrice,
        itemDiscount,
        itemDiscountType,
        quantity,
        itemOrder,
      });
      this.displayEditLineDialog = false;
    },
    lineTotalPrice: function (item) {
      let calcLineTotalPrice = 0;
      if (item.item_unit_price) {
        //@modified ana.castro 2021.08.16
        // if (item.discount.type === "percentage") {
        //   calcLineTotalPrice =
        //     item.item_unit_price *
        //     item.quantity *
        //     (1 - item.discount.value / 100);
        // } else {
        //   //@modified ana.castro 2021.04.20
        //   // (element.discount || 0) the OR was added because when this version will be installed, it is possible that
        //   // there is information stored on the Local Storage, which does not include the "discount"
        //   calcLineTotalPrice =
        //     item.item_unit_price * item.quantity -
        //     (item.discount_value_total || 0);
        // }
        calcLineTotalPrice = item.item_unit_price * item.quantity - (item.discount_value_total || 0);
      } else if (item.line_total_price) {
        calcLineTotalPrice = item.line_total_price;
      }

      return displayWithCents(round2Decimals(calcLineTotalPrice));
    },
  },
  computed: {
    // quantityRules: function (item) {
    //   let thiss = this;
    //   return () =>
    //   {
    //     let originalItem = thiss.itemsClone.find(oitem => oitem.item_code === item.item_code);
    //   // console.log('item', item)
    //   // Se enableChangeQuantityPlus == false, o máximo que deve ser aceite é item.quantity
    //   return [
    //     (v) => !!v || "Preenchimento Obrigatório",
    //     (v) => (v && v >= 0) || "Valor deve ser igual ou superior a 0",
    //     (v) => (v && v < originalItem.quantity) || "ERRO Valor deve ser igual ou superior a 0",
    //   ];
    // };
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
  },
  mounted() {
    this.itemsClone = [...this.items];
  },
};
</script>

<style scoped></style>
