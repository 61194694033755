<!--This Component displays Loyalty Campaigns which can be selected and passed to the Views which use this Component.
The Loyalty Campaigns are divided in three types:
1 - Vouchers with Value to discount;
2 - Vouchers with a Percentage to discount
3 - Campaigns .

For the first two types it is possible to select more than a Voucher of each type. However, the Campaigns only
allow the selection of one Campaign.

This View includes two buttons:
1 - a Cancel button which emits the close-dialog event;
2 - a Add button which emits the add-campaign event.
-->
<template>
  <v-card>
    <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.campaigns") }}</v-card-title>
    <v-card-text>
      <v-list two-line>
        <v-card max-width="450" class="mx-auto">
          <!-- Displaying the Vouchers with Value to Discount.
           This section allows the selection of more than a Voucher. -->
          <v-list-item-group
            v-if="loyaltyCampaignsVouchersValue.length > 0"
            v-model="selectedCampaigns"
            active-class="border"
            multiple
          >
            <template v-for="(item, index) in loyaltyCampaignsVouchersValue">
              <v-list-item v-if="item.code && item.value" :key="item.id" :value="item">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name + ' (' + item.value.toFixed(2) + currencySymbol + ') '"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider v-if="index < loyaltyCampaignsVouchersValue.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
          <v-divider
            v-if="
              loyaltyCampaignsVouchersValue.length > 0 &&
              (loyaltyCampaignsVouchersPercent.length > 0 || loyaltyCampaigns.length > 0)
            "
            class="my-2 grey"
          ></v-divider>

          <!-- Displaying the Vouchers with Percentage to Discount.
           This section allows the selection of more than a Voucher. -->
          <v-list-item-group
            v-if="loyaltyCampaignsVouchersPercent.length > 0"
            v-model="selectedCampaigns"
            active-class="border"
            multiple
          >
            <template v-for="(item, index) in loyaltyCampaignsVouchersPercent">
              <v-list-item v-if="item.code && item.percent" :key="item.id" :value="item">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider v-if="index < loyaltyCampaignsVouchersPercent.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
          <v-divider
            v-if="loyaltyCampaignsVouchersPercent.length > 0 && loyaltyCampaigns.length > 0"
            class="my-2 grey"
          ></v-divider>

          <!-- Displaying the Loyalty Campaigns.
          This section allows the selection of ONLY one Voucher. -->
          <v-list-item-group v-if="loyaltyCampaigns.length > 0" v-model="selectedCampaigns" active-class="border">
            <template v-for="(item, index) in loyaltyCampaigns">
              <v-list-item v-if="!item.code" :key="item.id" :value="item">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider v-if="index < loyaltyCampaigns.length - 1" :key="index"></v-divider>
            </template>
          </v-list-item-group>
        </v-card>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn large text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
      <v-btn large class="primary" @click="selectCampaign"> {{ $vuetify.lang.t("$vuetify.add") }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoyaltyCampaigns",
  props: {
    // The Loyalty Campaigns displayed by this Component are divided in three types:
    // - Campaigns
    loyaltyCampaigns: Array,
    // - Vouchers with Value to discount;
    loyaltyCampaignsVouchersValue: Array,
    // - Vouchers with a Percentage to discount
    loyaltyCampaignsVouchersPercent: Array,
  },
  data: () => ({
    selectedCampaigns: [],
  }),
  methods: {
    selectCampaign: function () {
      //The list of Loyalty Campaigns is divided in three types. Only two of the types allow the selection of more
      // than a Loyalty Campaign.
      //This difference in the way of selecting Loyalty Campaigns, results in a different way of storing the selected values:
      //  1 - when multiple choices are allowed, the selected Loyalty Campaigns are stored in a List;
      //  2 - otherwise, the selected value is a Loyalty Campaign.
      //The event emitted to the Main Component must send the selected Loyalty Campaigns always as a list.

      let localLoyaltyCampaigns = [];
      if (this.selectedCampaigns && !Array.isArray(this.selectedCampaigns)) {
        localLoyaltyCampaigns.push(this.selectedCampaigns);
      } else if (this.selectedCampaigns) {
        localLoyaltyCampaigns = this.selectedCampaigns;
      }

      //Sending the selected Campaigns to the CheckoutCart View
      this.$emit("add-campaign", localLoyaltyCampaigns);

      //Everytime the dialog to select the Campaigns is displayed, the possible previous selected Campaigns must not
      //  be included. Therefore, after emitting the event to the CheckoutCart View, the list of selected Campaigns
      // is reset.
      // Note: I've tried to use the beforeDestroy and beforeUnmount lifecycle hooks but none of them worked to
      // achieve this goal
      this.selectedCampaigns = [];
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
  },
  computed: {
    discountRules: function () {
      var errors = [];

      errors.push(
        (v) => v !== undefined || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) =>
          this.globalDiscountType !== "percentage" ||
          (v && parseFloat(v) <= 100 && parseFloat(v) >= 0) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0to100"),
        (v) =>
          this.globalDiscountType !== "value" ||
          (v && parseFloat(v) >= 0 && parseFloat(v) <= this.cartTotalAmountWithoutDiscount) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0toX") + this.cartTotalAmountWithoutDiscount
      );

      return errors;
    },
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
    }),
  },
};
</script>

<style scoped></style>
