<!-- TODO: limpar dados que possam existir no Form -->
<!-- TODO: disponibilizar data de nascimento-->
<!-- TODO: verificar pq é q o botão Gravar está disponível quando o form não está válido-->
<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $vuetify.lang.t("$vuetify.newClient") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newCustomer.name"
                :label="$vuetify.lang.t('$vuetify.name')"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newCustomer.emailAddress"
                :label="$vuetify.lang.t('$vuetify.email')"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="newCustomer.telephone"
                :label="$vuetify.lang.t('$vuetify.customerMobileTelephone')"
                :rules="phoneRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newCustomer.taxNumber"
                :label="$vuetify.lang.t('$vuetify.taxNumber')"
                :rules="taxNumberRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newCustomer.cardId"
                :label="$vuetify.lang.t('$vuetify.customerLoyaltyCard')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newCustomer.address"
                :label="$vuetify.lang.t('$vuetify.addressClient')"
                :rules="addressRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="newCustomer.postalCode"
                :label="$vuetify.lang.t('$vuetify.postalCode')"
                :rules="postalCodeRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newCustomer.city"
                :label="$vuetify.lang.t('$vuetify.location')"
                :rules="cityRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="newCustomer.country"
                :label="$vuetify.lang.t('$vuetify.country')"
                :items="countryOptions()"
                required
                item-value="value"
                item-text="text"
                return-object
              ></v-select>
            </v-col>

            <!-- @deprecated não suportado pelo serviço de criação dos Clientes             -->
            <!--            <v-col cols="12">-->
            <!--              <v-autocomplete-->
            <!--                :items="['', 'Feminino', 'Masculino']"-->
            <!--                label="Género"-->
            <!--              ></v-autocomplete>-->
            <!--            </v-col>-->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>
        <v-btn class="primary" text @click="submit" :disabled="!valid"> {{ $vuetify.lang.t("$vuetify.save") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validateTaxNumberPT, getErrorMessage } from "../_helpers";

export default {
  name: "Customer",
  data: () => ({
    valid: true,
    newCustomer: {},
    countries: [],
  }),
  methods: {
    getAllCountries() {
      this.$store.getters["countries/getAll"].then(
        (result) => {
          this.countries = result;
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorGettingCountries"));
        }
      );
    },
    countryOptions() {
      var returnOptions = [];

      for (var i = 0; i < this.countries.length; i++) {
        returnOptions.push({ text: this.countries[i]["name"], value: this.countries[i]["ISOCountryId"] });
      }

      return returnOptions;
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    submit: function () {
      this.$emit("create-customer", this.newCustomer);
    },
  },
  computed: {
    /**
     * Validations applied to the field NAME:
     * - mandatory
     * - can't have more than 20 characters
     * - exactly two names
     * @returns {Array} an array of validations
     */
    nameRules: function () {
      return [
        (v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) => (v && v.length <= 20) || this.$vuetify.lang.t("$vuetify.nameMax20"),
        (v) => (v && v.trim().split(" ").length >= 2) || this.$vuetify.lang.t("$vuetify.firstAndLastName"),
      ];
    },
    /**
     * Validations applied to the field BIRTH DATE:
     * - mandatory
     * @returns {Array} an array of validations
     * */
    birthDateRules: function () {
      return [(v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField")];
    },
    /**
     * The field EMAIL is mandatory depending on the definition which is given by the getprofiledefinitions call.
     * When the field EMAIL is filled in, its content is checked to validate its format.
     * @returns {Array} an array of validations
     */
    emailRules() {
      var errors = [];
      // eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //source: https://vuejs.org/v2/cookbook/form-validation.html#Using-Custom-Validation

      //If the email is not mandatory and it is not filled in, the 1st expression returns False and it is necessary to verify the 2nd condition
      //  the 2nd condition validates the email format, when the email is filled in
      errors.push((v) => {
        if (!v) {
          return true;
        } else if (v && v.trim().length > 0 && !re.test(v.trim())) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidEmail");
        }

        return true;
      });

      return errors;
    },
    phoneRules: function () {
      var errors = [];
      let re = /^\d+$/;

      errors.push((v) => !!v || this.$vuetify.lang.t("$vuetify.mandatoryField"));

      errors.push((v) => (v && re.test(v.trim())) || this.$vuetify.lang.t("$vuetify.errorInvalidMobile"));

      return errors;
    },
    taxNumberRules: function () {
      let errors = [];

      //Only digits: has to be length 9 and validateTaxNumberPT
      //Starts with PT/pt:  has to be length 11 and validateTaxNumberPT
      //Another formats: no validations are applied
      errors.push((v) => {
        if (!v) {
          return true;
        } else if (
          ((/^\d+$/.test(v) && v.length === 9) || (/^PT\d+$/i.test(v) && v.length === 11)) &&
          !validateTaxNumberPT(v.toUpperCase().replace("PT", ""))
        ) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        } else if ((/^\d+$/.test(v) && v.length !== 9) || (/^PT\d+$/i.test(v) && v.length !== 11)) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN9");
        } else if (v.length < 3) {
          return this.$vuetify.lang.t("$vuetify.errorInvalidTIN");
        }

        return true;
      });

      return errors;
    },
    /**
     * Validations applied to the field ADDRESS DETAIL:
     * (- mandatory)
     * - can't have more than 200 characters
     * @returns {Array} an array of validations
     */
    addressRules: function () {
      let errors = [];

      errors.push((v) => {
        if (!v) {
          return true;
        } else if (v && v.length > 200) {
          return this.$vuetify.lang.t("$vuetify.addressMax200");
        }

        return true;
      });

      return errors;
    },
    postalCodeRules: function () {
      let errors = [];

      errors.push((v) => {
        if (!v) {
          return true;
        } else if (v && v.length > 20) {
          return this.$vuetify.lang.t("$vuetify.postalCodeMax20");
        }

        return true;
      });

      return errors;
    },
    cityRules: function () {
      let errors = [];

      errors.push((v) => {
        if (!v) {
          return true;
        } else if (v && v.length > 100) {
          return this.$vuetify.lang.t("$vuetify.locationMax100");
        }

        return true;
      });

      return errors;
    },
    countryRules: function () {
      return [
        // (v) => !!v || "Preenchimento Obrigatório",
        (v) => (v && v.length <= 2) || this.$vuetify.lang.t("$vuetify.countryMax2"),
      ];
    },
  },
  mounted() {
    this.getAllCountries();
  },
};
</script>

<style scoped></style>
